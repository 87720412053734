import React from 'react';

import { DataGridContainer, Footer, Header, Wrapper } from '../styles';

const AbstractWrapper: React.FC<{
  headerTitle: string;
  headerAction?: React.ReactElement;
  girdElement: React.ReactElement;
  footerAction?: React.ReactElement;
}> = ({ headerAction, headerTitle, girdElement, footerAction }): React.ReactElement => (
  <Wrapper>
    <Header>
      <h1>{headerTitle}</h1>
      {headerAction}
    </Header>
    <DataGridContainer>{girdElement}</DataGridContainer>

    <Footer>{footerAction}</Footer>
  </Wrapper>
);

export default AbstractWrapper;

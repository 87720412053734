/* eslint-disable guard-for-in */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPrivatePath = (r: any, pathname: string): boolean => {
  for (const key in r) {
    const route = r[key];
    if (typeof route === 'string' && route === pathname) {
      return true;
    }
    if (typeof route === 'object') {
      if (typeof route.details !== 'string') {
        return true;
      }
      if ((route.details && pathname.startsWith(route.details.split(':')[0])) || isPrivatePath(route, pathname)) {
        return true;
      }
    }
  }
  return false;
};

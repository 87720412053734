import styled from 'styled-components';

export const FieldHeader = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  margin: 16px 0 6px 0;
`;

export const StyledModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;

export const WarningContainer = styled.div`
  display: flex;
  padding: 6px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  background: rgba(237, 108, 2, 0.1);

  p {
    color: rgb(161, 73, 0);
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.15px;
    margin: 14px 0;
    padding: 0;
  }
`;

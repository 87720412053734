import React from 'react';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { observer } from 'mobx-react';

import Grid from 'components/Grid';
import { ColumnDefinition } from 'components/Grid/types';

import { DisplayProtectorType, EntityFieldType, ProtectorType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import ActionCell from '../ActionCell';
import CreateEditFieldModal from '../CreateEditFieldModal';

export const basicColumns = ([
  {
    field: 'name',
    headerName: 'Field label',
    sortable: false,
  },
  {
    field: 'key',
    headerName: 'API name',
    sortable: false,
  },
  {
    field: 'protectorType',
    headerName: 'Field type',
    renderCell: ({ value }: { value: ProtectorType }) => <p>{DisplayProtectorType[value]}</p>,
    sortable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    maxWidth: 50,
    sortable: false,
    ariaLabel: 'actions_cell',
    renderCell: ({ row, colDef }: { row: EntityFieldType; colDef: ColumnDefinition }) => (
      <ActionCell rowData={row} actions={colDef.actions} />
    ),
    filterOperators: null,
  },
] as unknown) as ColumnDefinition[];

const FieldManagerTable = ({
  moduleName,
  onError,
}: {
  moduleName: string;
  onError: (error: VatixError) => void;
}): React.ReactElement => {
  const { entityFields } = useStore();

  const refreshRef = React.useRef<(() => void) | null>(null);

  const onRowClick: GridEventListener<'rowClick'> = (field: GridRowParams): void => {
    entityFields.setActiveField((field.row as unknown) as EntityFieldType);
  };

  return (
    <>
      <Grid
        basicColumns={basicColumns}
        entity="field"
        onError={onError}
        onRefreshCallback={(callback) => {
          refreshRef.current = callback;
        }}
        dataURL={`entities/${moduleName}/fields/`}
        onRowClick={onRowClick}
        showHeader={false}
      />
      {entityFields.activeField !== null && (
        <CreateEditFieldModal
          entityType={moduleName}
          editedValues={entityFields.activeField}
          onClose={(refresh: boolean) => {
            entityFields.setActiveField(null);
            if (refresh) {
              refreshRef.current?.();
            }
          }}
        />
      )}
    </>
  );
};

export default observer(FieldManagerTable);

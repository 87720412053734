import { Popover } from '@mui/material';
import styled from 'styled-components';

export const Header = styled.div<{ $expanded?: boolean }>`
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: ${({ $expanded }) => ($expanded ? '0px 8px 0px 0px' : '0px 8px 8px 0px')};
  border-bottom: ${({ $expanded }) => ($expanded ? '1px solid #e2e4e6' : '')};
  background: #f5f5f5;

  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.17px;
    margin: 0;
    padding: 0;
  }
`;

export const StyledPopover = styled(Popover)`
  box-shadow: none;
  border: none;
  border-radius: 8px;
`;

import React from 'react';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import TabPanel from 'components/CustomTabPanel/CustomTabPanel';

import { StyledTab, StyledTabs, TabsContainer } from './styles';
import SiteDetailsTab from '../SiteDetailsTab';
import SiteRelatedTab from '../SiteRelatedTab';

const SiteDetailsContent: React.FC<{ onError: (error: VatixError) => void }> = ({ onError }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  return (
    <TabsContainer>
      <StyledTabs value={value} onChange={handleChange}>
        <StyledTab label="Details" id="site-overview-tab" />
        <StyledTab label="Related" id="related-tab" />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <SiteDetailsTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SiteRelatedTab onError={onError} />
      </TabPanel>
    </TabsContainer>
  );
};

export default SiteDetailsContent;

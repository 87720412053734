import React from 'react';

import { GridValueGetterParams, GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { reverse } from 'named-urls';

import Grid, { defaultRenderers } from 'components/Grid';

import { ExtendedUserOperators } from 'components/Grid/operators/User/User';

import { ColumnDefinition } from 'components/Grid/types';

import { Site } from 'containers/Sites/types';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import { formatDateForDisplay } from 'utils/formatters/time';

import ActionCell from '../ActionCell';
import { SitesTableProps } from './types';
import { StyledCreated } from './styles';

export const basicColumns = ([
  {
    field: 'siteName',
    headerName: 'Name',
  },
  {
    field: 'siteManager',
    headerName: 'Manager',
    renderCell: defaultRenderers.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    filterOperators: ExtendedUserOperators(),
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    renderCell: ({ value }: { value: string }) => <StyledCreated>{formatDateForDisplay(value)}</StyledCreated>,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    maxWidth: 50,
    ariaLabel: 'actions_cell',
    renderCell: ({ row, colDef }: { row: Site; colDef: ColumnDefinition }) => (
      <ActionCell rowData={row} actions={colDef.actions} />
    ),
    filterOperators: null,
    sortable: false,
  },
] as unknown) as ColumnDefinition[];

const SitesTable: React.FunctionComponent<SitesTableProps> = ({ onError, onRefreshCallback }): React.ReactElement => {
  const { routing } = useStore();

  const onRowClick: GridEventListener<'rowClick'> = (item: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.sites.details, { siteId: item.id }));
  };

  return (
    <Grid
      basicColumns={basicColumns}
      entity="sites"
      onError={onError}
      dataURL="/api/entities/sites/"
      customNoRowsText="No sites have been created yet"
      onRowClick={onRowClick}
      onRefreshCallback={onRefreshCallback}
    />
  );
};

export default SitesTable;

import { styled } from 'styled-components';

export const InputHeader = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
`;

export const StyledOption = styled.li`
  font-size: 16px;
  min-height: 24px !important;
`;

import React from 'react';

import { GridValidRowModel, GridValueGetterParams } from '@mui/x-data-grid';

import { ColumnDefinition } from 'components/Grid/types';

import UserCell from 'containers/Users/components/UserCell';

import AdminCell from './components/AdminCell';
import ActionCell from './components/ActionCell';

export const basicColumns = ([
  {
    field: 'name',
    headerName: 'Name',
    valueGetter: (params: GridValueGetterParams) => ({ name: params.row.name, uuid: params.row.uuid }),
    renderCell: ({ value }: { value: { name: string; uuid: string } }) => (
      <UserCell name={value.name} uuid={value.uuid} />
    ),
  },
  {
    field: 'isAdmin',
    headerName: '',
    valueGetter: (params: GridValueGetterParams) => ({ isAdmin: params.row.isAdmin, uuid: params.row.uuid }),
    renderCell: ({ value, row }: { value: { isAdmin: boolean; uuid: string }; row: GridValidRowModel }) => (
      <AdminCell isAdmin={value.isAdmin} row={row} />
    ),
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    maxWidth: 50,
    ariaLabel: 'test',
    renderCell: ({ row }: { row: GridValidRowModel }) => <ActionCell rowData={row} />,
    sortable: false,
  },
] as unknown) as ColumnDefinition[];

import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosError, AxiosResponse } from 'axios';

import { reverse } from 'named-urls';

import _ from 'lodash';

import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import Logger from 'utils/logger';
import {
  AnswerType,
  AnswerTypes,
  CustomIncidentTypeResponse,
  IncidentResponse,
  JSONSchemaType,
  ProtectorType,
  UpdateIncidentResponse,
} from 'utils/api/types';

import { apiErrors, isNotFound } from 'utils/api/errors';

import { postMessage } from 'utils/events/broadcast';

import routes from 'core/routes';

import { getMissingElements, getMissingElementsWithSections, schemaContainsSection } from 'containers/Incidents/utils';

import { isNumberValid, validDateTimeValue, validDateValue, validTimeValue } from 'containers/IncidentDetails/utils';

import SchemaBuilder from 'stores/SchemaBuilder/SchemaBuilder';

import IncidentActivities from './IncidentActivities';

import RootStore from '../Root';
import ReporterActivities from './ReporterActivities';
import SubmissionActivities from './SubmissionActivities';

export default class IncidentDetailsStore {
  store: RootStore;

  api: typeof API;

  @observable isLoaded = false;

  @observable error?: AxiosError;

  @observable details?: IncidentResponse;

  @observable activities?: IncidentActivities;

  @observable reportActivities?: ReporterActivities;

  @observable submissionActivities?: SubmissionActivities;

  @observable customIncidentTypeLoading = false;

  @observable originalIncidentSchema?: JSONSchemaType;

  @observable customIncidentType?: CustomIncidentTypeResponse;

  @observable hasError = false;

  @observable showConfirmPopup = false;

  @observable incidentContent?: AnswerType;

  @observable contentOfIncidentWasEdited = false;

  @observable editedContent?: AnswerType;

  @observable missingRequiredValues: string[] = [];

  @observable extraParamsForCreatingIncident?: { siteId: string };

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;
    eventBus.register(this);
  }

  @action.bound
  resetContent(): void {
    this.incidentContent = undefined;
  }

  @action.bound
  updateIncidentField(section: string | undefined, field: string, value: AnswerTypes): void {
    if (!this.originalIncidentSchema || !this.customIncidentType) {
      return;
    }
    this.missingRequiredValues = this.missingRequiredValues.filter((v) => v !== field);
    if (section) {
      this.incidentContent = {
        ...(this.incidentContent || {}),
        [section]: { ...((this.incidentContent?.[section] as AnswerType) || {}), [field]: value },
      } as AnswerType;
    } else {
      this.incidentContent = {
        ...(this.incidentContent || {}),
        [field]: value,
      } as AnswerType;
    }
    const builder = new SchemaBuilder(
      this.originalIncidentSchema,
      this.incidentContent as AnswerType,
      Object.keys(this.originalIncidentSchema.properties).some(
        (k) => this.originalIncidentSchema?.properties[k].protectorType === ProtectorType.Section
      )
    );

    // @ts-ignore
    this.customIncidentType.schema = builder
      .checkOrder()
      .checkRequired()
      .produce();
  }

  async loadIncidentType(
    fetchData: () => Promise<AxiosResponse<CustomIncidentTypeResponse>>,
    errorHandler: (error: unknown) => void
  ): Promise<void> {
    try {
      this.submissionActivities = new SubmissionActivities(this.store, this.api);
      this.customIncidentTypeLoading = true;
      const { data } = await fetchData();
      runInAction(() => {
        this.originalIncidentSchema = data.schema;
        const schemaHasSections = schemaContainsSection(data.schema);
        const schema = new SchemaBuilder(data.schema, this.incidentContent || {}, Boolean(schemaHasSections))
          .checkOrder()
          .checkRequired()
          .produce() as JSONSchemaType & { required: string[] };

        this.customIncidentType = {
          ...data,
          schema,
        };
      });
    } catch (e) {
      this.hasError = true;
      errorHandler(e);
    } finally {
      this.customIncidentTypeLoading = false;
    }
  }

  @action.bound
  async loadCustomIncidentType(): Promise<void> {
    await this.loadIncidentType(
      () => this.api.userCustomIncidentType()(),
      (e) => Logger.error(`Invalid custom incident load API response`, e)
    );
  }

  @action.bound
  async loadPublicIncidentType(organisationId: string): Promise<void> {
    await this.loadIncidentType(
      () => this.api.loadPublicSubmission(organisationId)(),
      (e) => Logger.error(`Invalid public incident load API response`, e)
    );
  }

  @action.bound
  async loadDetails(incidentId: string): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadIncidentDetails(incidentId)();
      runInAction(() => {
        this.error = undefined;
        this.details = data;
        this.activities = new IncidentActivities(this.store, this.api, incidentId);
        this.reportActivities = new ReporterActivities(this.store, this.api, incidentId);
      });
    } catch (e) {
      runInAction(() => {
        this.error = e as AxiosError<unknown>;
      });
      if (!isNotFound(e as AxiosError<unknown>)) {
        Logger.error(`Invalid load incident details API response. Incident id: ${incidentId}`, e);
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  checkContent(content: AnswerType, schema: JSONSchemaType, editing = false): boolean {
    const schemaHasSections = schemaContainsSection(schema);

    const missingRequiredValues = schemaHasSections
      ? getMissingElementsWithSections(content, schema)
      : getMissingElements(schema.required || [], content);

    if (!editing && missingRequiredValues.length > 0) {
      this.store.notification.enqueueErrorSnackbar(`You have to fill in all required fields.`);
      this.missingRequiredValues = missingRequiredValues;
      return false;
    }
    if (!validDateValue(schema, content, schemaHasSections)) {
      this.store.notification.enqueueErrorSnackbar(
        'Invalid date format. Please enter a date in the format DD/MM/YYYY.'
      );
      return false;
    }
    if (!validTimeValue(schema, content, schemaHasSections)) {
      this.store.notification.enqueueErrorSnackbar('Invalid time format. Please enter a time in the format hh:mm aa.');
      return false;
    }
    if (!validDateTimeValue(schema, content, schemaHasSections)) {
      this.store.notification.enqueueErrorSnackbar(
        'Invalid date and time format. Please enter a date and time in the format DD/MM/YYYY hh:mm aa.'
      );
      return false;
    }
    if (!isNumberValid(schema, content, schemaHasSections)) {
      this.store.notification.enqueueErrorSnackbar('Invalid value in a number input.');
      return false;
    }
    return true;
  }

  successfullyCreatedIncident(data: IncidentResponse): void {
    this.store.routing.push(reverse(routes.dashboard.incidents.details, { incidentId: data.uuid }));
  }

  @action.bound
  changeShowConfirmPopup(value: boolean): void {
    this.showConfirmPopup = value;
  }

  @action.bound
  setExtraParamsForCreatingIncident(params: { siteId: string } | undefined): void {
    this.extraParamsForCreatingIncident = params;
  }

  private async createIncidentCommon(
    content: AnswerType,
    schema: JSONSchemaType,
    apiCall: (contentWithStatus: AnswerType) => Promise<AxiosResponse<IncidentResponse>>,
    postSuccessAction: (data: IncidentResponse, organisationId?: string) => Promise<void> | void,
    organisationId?: string
  ): Promise<IncidentResponse | null> {
    if (!schema.properties || !this.checkContent(content, schema)) {
      return null;
    }

    let contentWithStatus = { ...content, status: schema.properties.status.default } as AnswerType;

    try {
      // if creating incident from site details view we need to get site uuid from extra params
      if (this.extraParamsForCreatingIncident) {
        contentWithStatus = { ...contentWithStatus, site: this.extraParamsForCreatingIncident.siteId };
      }

      const { data } = await apiCall(contentWithStatus);

      if (
        this.submissionActivities &&
        this.submissionActivities.activities &&
        this.submissionActivities.activities?.length > 0
      ) {
        await postSuccessAction(data, organisationId);
      } else {
        this.changeShowConfirmPopup(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.changeShowConfirmPopup(false);
        if (!organisationId) {
          this.successfullyCreatedIncident(data);
        } else {
          this.submissionActivities?.changeFinishedUploading(true);
        }
      }
      // clear extra params
      this.setExtraParamsForCreatingIncident(undefined);
    } catch (e) {
      Logger.error('Invalid create incident API response');
      this.store.notification.enqueueErrorSnackbar('Incident cannot be created right now.');
      return null;
    }
    return null;
  }

  @action.bound
  async createIncident(content: AnswerType, schema: JSONSchemaType): Promise<IncidentResponse | null> {
    return this.createIncidentCommon(
      content,
      schema,
      (contentWithStatus) =>
        this.api.createCustomIncident({
          details: {
            content: contentWithStatus,
            schema,
          },
          site: this.extraParamsForCreatingIncident ? this.extraParamsForCreatingIncident.siteId : undefined,
        })(),
      (data) => {
        this.submissionActivities?.addSubmissionActivity(data.uuid, () => this.successfullyCreatedIncident(data));
      }
    );
  }

  @action.bound
  async createPublicIncident(
    submission: AnswerType,
    schema: JSONSchemaType,
    organisationId: string
  ): Promise<IncidentResponse | null> {
    const site = this.extraParamsForCreatingIncident ? this.extraParamsForCreatingIncident.siteId : undefined;
    return this.createIncidentCommon(
      submission,
      schema,
      (contentWithStatus) =>
        this.api.createPublicSubmission({ submission: contentWithStatus, schema, site }, organisationId)(),
      (data, orgId) => {
        this.submissionActivities?.addSubmissionActivity(data.uuid, undefined, orgId);
      },
      organisationId
    );
  }

  @action.bound
  async createTaskRelatedToIncident(
    name: string,
    incidentId: string | null,
    assigned: string,
    dueDate: string | null
  ): Promise<boolean> {
    try {
      const { data } = await this.api.createTask({
        name,
        customIncident: incidentId,
        assigned,
        dueDate,
      })();
      this.store.notification.enqueueSuccessSnackbar('New action created');
      postMessage(EventType.CreatedTask, data);
      if (this.details && this.details.linkedTasks) {
        this.details.linkedTasks.total = (this.details.linkedTasks?.total ?? 0) + 1;
      }
      return true;
    } catch (e) {
      this.store.notification.enqueueErrorSnackbar('The action could not be created. Something went wrong.');
      return false;
    }
  }

  @action.bound
  async updateIncidentData(newData: UpdateIncidentResponse): Promise<boolean> {
    const details = this.details as IncidentResponse;
    if (newData.subject === '') {
      this.store.notification.enqueueErrorSnackbar("Name can't be blank");
      return false;
    }
    try {
      const { data } = await this.api.updateIncident(details.uuid, newData)();
      runInAction(() => {
        this.details = data;
      });
      this.store.notification.enqueueSuccessSnackbar(`Incident updated successfully`);
      this.setContentOfIncidentWasEdited(false);
      this.setEditedContent(this.details?.details.content as AnswerType);
      this.activities?.updatedIncident();
      return true;
    } catch (e) {
      Logger.error(`Invalid incident updated action API response`, e);
      const errors = apiErrors(e as AxiosError, `Incident cannot be updated right now`);

      errors.forEach((error: string) => {
        this.store.notification.enqueueErrorSnackbar(error);
      });
      return false;
    }
  }

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;

    if (this.activities) {
      this.activities.reset();
      this.activities = undefined;
    }

    if (this.reportActivities) {
      this.reportActivities.reset();
      this.reportActivities = undefined;
    }
  }

  @action
  async deleteIncident(uuid: string): Promise<void> {
    try {
      await this.api.updateIncident(uuid, {
        isDeleted: true,
      })();
      this.store.notification.enqueueSuccessSnackbar(`Incident deleted successfully`);
    } catch (e) {
      Logger.error(`Invalid incident deleted action API response`, e);
      const errors = apiErrors(e as AxiosError, `Incident cannot be deleted right now`);

      errors.forEach((error: string) => {
        this.store.notification.enqueueErrorSnackbar(error);
      });
    }
  }

  @action
  setEditedContent(newContent: AnswerType | null): void {
    if (newContent === null) {
      this.contentOfIncidentWasEdited = false;
      this.editedContent = undefined;
    } else {
      this.editedContent = newContent;
      if (this.contentHasBeenEdited()) {
        this.contentOfIncidentWasEdited = true;
      } else {
        this.contentOfIncidentWasEdited = false;
      }
    }
  }

  @action
  setContentOfIncidentWasEdited(newValue: boolean): void {
    this.contentOfIncidentWasEdited = newValue;
  }

  @action.bound
  contentHasBeenEdited(): boolean {
    if (this.contentOfIncidentWasEdited) {
      return true;
    }
    if (this.editedContent === undefined) {
      return false;
    }
    const oldData = this.details?.details.content;
    const newData = this.editedContent;

    if (!newData) {
      return true;
    }

    const changed = _.reduce(
      oldData,
      // @ts-ignore
      (result, value, key) => (_.isEqual(value, newData[key]) ? result : result.concat(key)),
      []
    );
    // no changes => []
    return changed.length !== 0;
  }
}

import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { useNavBarHeight } from 'utils/hooks/navbar';

import FormsList from './components/FormsList/FormsList';
import FormsHeader from './components/FormsList/FormsHeader';

const Forms = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();

  return (
    <>
      <Record
        navBarHeight={navBarHeight}
        Content={(onError) => <FormsList onError={onError} />}
        Header={<FormsHeader />}
      />
    </>
  );
};

export default Forms;

import React from 'react';
import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';

import { BackgroundInformationSections } from './styles';

import SitesSection from './SitesSection';

const SiteDetailsTab: React.FunctionComponent = (): React.ReactElement => {
  const {
    siteDetails: { details },
  } = useStore();

  if (!details) {
    return <></>;
  }
  return (
    <BackgroundInformationSections>
      {details.layout.order.map((key) => {
        const section = details.layout.properties[key];
        if (!section) return null;
        return <SitesSection title={section.title} fieldOrder={section.order} />;
      })}
    </BackgroundInformationSections>
  );
};

export default observer(SiteDetailsTab);

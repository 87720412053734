import React, { forwardRef } from 'react';

import { observable } from 'mobx';

import { UniqueIdentifier } from '@dnd-kit/core';

import { AnimateLayoutChanges, useSortable, defaultAnimateLayoutChanges } from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';
import { Collapse } from '@mui/material';

import { Handle } from '../helpers/Handle';
import { ContainerProps } from './types';
import { Container as StyledContainer, Actions } from './styles';
import ContainerHeader from '../ContainerHeader/ContainerHeader';

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export function DroppableContainer({
  children,
  disabled,
  id,
  items,
  withoutHeader = false,
  ...props
}: ContainerProps & {
  disabled?: boolean;
  id: UniqueIdentifier;
  items: UniqueIdentifier[];
  style?: React.CSSProperties;
}): React.ReactElement {
  const { attributes, isDragging, listeners, setNodeRef, transition, transform } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
        border: isDragging ? '1px solid rgb(7, 114, 229)' : undefined,
      }}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      withoutHeader={withoutHeader}
      id={id}
      {...props}
    >
      {children}
    </Container>
  );
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      children,
      label,
      handleProps,
      onClick,
      onRemove,
      disableActionsHeader: disableActions,
      withoutHeader,
      isActive,
      id,
      ...props
    }: ContainerProps,
    ref
  ) => {
    const [expanded, setExpanded] = React.useState(true);

    return (
      <StyledContainer
        {...props}
        ref={ref}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
        $withoutHeader={withoutHeader}
        $isActive={isActive}
      >
        {!disableActions && (
          <Actions $isActive={isActive}>
            <Handle {...handleProps} />
          </Actions>
        )}
        <div style={{ flex: 1 }}>
          {!withoutHeader && (
            <ContainerHeader expanded={expanded} setExpanded={setExpanded} label={label} onRemove={onRemove} id={id} />
          )}
          <Collapse
            in={expanded}
            style={{
              margin: expanded ? '6px' : '',
            }}
          >
            {children}
          </Collapse>
        </div>
      </StyledContainer>
    );
  }
);

export default observable(Container);

import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';
import { useRouteMatch } from 'react-router-dom';
import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';
import { NotFound } from 'vatix-ui/lib/components/Error/Pages';

import DataError from 'vatix-ui/lib/components/Error/DataError';

import { isNotFound } from 'utils/api/errors';

import { useNavBarHeight } from 'utils/hooks/navbar';
import { useStore } from 'utils/hooks/store';

import SitesDetailsActivitiesFooter from './components/SitesDetailsActivities/SitesDetailsActivitiesFooter';
import SitesDetailsActivities from './components/SitesDetailsActivities';
import SiteDetailsContent from './components/SiteDetailsContent';
import SiteDetailsHeader from './components/SiteDetailsHeader';

const SiteDetails: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const match = useRouteMatch<{ siteId: string }>();

  const { siteDetails } = useStore();

  return (
    <DetailsLoader
      match={match}
      routeParam="siteId"
      // @ts-ignore
      store={siteDetails}
    >
      {({ isLoaded, error, reload }): React.ReactElement => {
        if (!isLoaded) {
          return <CenteredCircularProgress />;
        }
        if (siteDetails.details === undefined) {
          return <NotFound />;
        }
        if (error) {
          if (isNotFound(error)) {
            return <NotFound />;
          }
          return <DataError onReload={reload} />;
        }
        return (
          <Record
            navBarHeight={navBarHeight}
            Header={
              <SiteDetailsHeader
                entity={siteDetails.details.entity}
                createdAt={siteDetails.details.createdAt}
                uuid={siteDetails.details.uuid}
              />
            }
            Content={(onError) => <SiteDetailsContent onError={onError} />}
            Activities={{
              Content: <SitesDetailsActivities />,
              Footer: <SitesDetailsActivitiesFooter />,
            }}
          />
        );
      }}
    </DetailsLoader>
  );
};

export default SiteDetails;

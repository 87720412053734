import { styled } from 'styled-components';
import { Popover } from '@mui/material';

export const SiteDetailsHeaderWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  display: flex;
  padding: 20px;
  padding-right: 40px;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  align-self: stretch;
  margin-top: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  h1 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 600;
    line-height: 143%;
    letter-spacing: 0.17px;
    margin: 0;
    margin-bottom: 6px;
  }
  p {
    font-weight: 400;
    letter-spacing: 0.15px;
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  button {
    margin-left: 1rem;
  }
`;

export const StyledPopover = styled(Popover)`
  box-shadow: none;
  border: none;
  border-radius: 8px;
`;

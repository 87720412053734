import { Tabs, Tab } from '@mui/material';
import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  padding: 10px 0px 0px 0px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  overflow-y: auto;
`;

export const StyledTabs = styled(Tabs)`
  width: 100%;
  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
  padding-top: 0px;

  .MuiTabs-scroller {
    border-bottom: 1px solid #e0e0e0;
  }
`;

export const StyledTab = styled(Tab)`
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.4px;
  text-transform: capitalize;
`;

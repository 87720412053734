import React from 'react';
import { observer } from 'mobx-react';

import { reverse } from 'named-urls';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import { Divider, IconButton } from '@mui/material';

import { circularProgressClasses } from '@mui/material/CircularProgress';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { AxiosError } from 'axios';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import { JSONSchemaType } from 'utils/api/types';

import API from 'utils/api';

import { isBadRequest } from 'utils/api/errors';

import Logger from 'utils/logger';

import { formatDateForDisplay, useTimeZone } from 'utils/formatters/time';

import { UserRole } from 'core/constants';

import IncidentStatusChip from 'components/Chips/IncidentStatusChip';
import UserField from 'containers/IncidentDetails/components/UserField';
import Line from 'components/Icons/Line';

import { StyledCircularProgress } from 'containers/Incidents/components/LinkedTasksCell/styles';

import RemoveEntityModal from 'components/RemoveEntityModal';

import {
  MenuItem,
  ButtonWrapper,
  StyledPopover,
  MainInformation,
  TasksLabel,
  TasksContainer,
  TasksIconContainer,
  ReporterCell,
} from './styles';

const IncidentDetailsHeader: React.FC = (): React.ReactElement => {
  const {
    incidentDetails: { details },
    incidentDetails,
    session,
    routing,
  } = useStore();

  if (details === undefined) {
    return <></>;
  }
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const userRole = session.user?.role as UserRole;
  const tz = useTimeZone();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleRemoveClick = async (): Promise<void> => {
    setOpenModal(true);
  };
  const closeModal = (): void => {
    setOpenModal(false);
  };
  const handleRemoveIncident = async (): Promise<void> => {
    await incidentDetails.deleteIncident(details.uuid);
    routing.push(reverse(routes.dashboard.incidents.toString()));
  };

  const handleExportIncident = React.useCallback(async (): Promise<void> => {
    if (!details) {
      return;
    }
    try {
      await API.exportIncident(details.uuid, { time_zone: tz })();
    } catch (e) {
      if (!isBadRequest(e as AxiosError)) {
        Logger.error(`Invalid complete red alert API response.`, e);
      }
    }
  }, [details, tz]);

  const relatedTaskTotal = details?.linkedTasks ? details?.linkedTasks.total : 0;
  const relatedTasksCompleted = details?.linkedTasks ? details?.linkedTasks.completed : 0;

  const handlePopoverClose = (): void => setAnchorEl(null);
  React.useEffect(() => {
    document.addEventListener('scroll', handlePopoverClose, true);
  }, []);

  React.useEffect(
    () => () => {
      document.removeEventListener('scroll', handlePopoverClose, true);
    },
    []
  );

  return (
    <>
      <MainHeader
        title={details.subject}
        breadcrumbs={[
          {
            label: 'Incidents',
            link: String(routes.dashboard.incidents),
          },
          {
            label: 'Overview',
            link: String(routes.dashboard.incidents),
          },
          {
            label: 'Details',
            link: 'Incidents',
          },
        ]}
        action={
          <>
            <ButtonWrapper>
              {details.incidentType === 'custom' && (
                <IconButton onClick={handleClick}>
                  <MoreVertIcon htmlColor="rgba(0, 0, 0, 0.54)" />
                </IconButton>
              )}
            </ButtonWrapper>
            <StyledPopover
              id="popover-export-incident"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              disableScrollLock
            >
              <MenuItem id="export-button" onClick={handleExportIncident}>
                Export
              </MenuItem>
              {[UserRole.Admin, UserRole.AccountManager, UserRole.Manager].includes(userRole) && (
                <>
                  <Divider style={{ margin: 0 }} />
                  <MenuItem id="remove-button" onClick={handleRemoveClick}>
                    Delete
                  </MenuItem>
                </>
              )}
            </StyledPopover>
          </>
        }
      />
      <MainInformation>
        <ReporterCell id="incident-header-reporter">
          <h1>Reporter</h1>
          <UserField userId={details?.creator?.uuid as string} />
        </ReporterCell>
        <div id="incident-header-submitted">
          <h1>Submitted</h1>
          {formatDateForDisplay(details.created)}
        </div>
        <div id="incident-header-status">
          <h1>Status</h1>
          {details.status.value ? (
            <IncidentStatusChip
              schema={details.status.schema as JSONSchemaType}
              label={details.status.value as string}
            />
          ) : (
            <p>N/A</p>
          )}
        </div>
        <div id="incident-header-type">
          <h1>Type</h1>
          {details.customType ? <p>{details.customType as string}</p> : <p>N/A</p>}
        </div>

        <div id="incident-header-owner">
          <h1>Owner</h1>
          <UserField userId={details?.owner?.uuid as string} />
        </div>

        <div id="incident-header-related-tasks">
          <h1>Related tasks</h1>
          {relatedTaskTotal ? (
            <TasksContainer>
              <TasksIconContainer>
                <StyledCircularProgress value={100} sx={{ color: 'rgba(224, 224, 224, 1)' }} />
                <StyledCircularProgress
                  data-test-id="styled-circular-progress"
                  value={relatedTaskTotal === 0 ? 100 : (relatedTasksCompleted * 100) / relatedTaskTotal}
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: '1px',
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: 'round',
                    },
                  }}
                />
              </TasksIconContainer>
              <TasksLabel>
                {relatedTasksCompleted} / {relatedTaskTotal}
              </TasksLabel>
            </TasksContainer>
          ) : (
            <TasksContainer>
              <TasksIconContainer>
                <StyledCircularProgress value={100} sx={{ color: 'rgba(224, 224, 224, 1)' }} />
              </TasksIconContainer>
              <Line style={{ fontSize: '17px', paddingTop: '6px' }} />
            </TasksContainer>
          )}
        </div>
      </MainInformation>
      {openModal && (
        <RemoveEntityModal onClose={closeModal} handleRemoveEntity={handleRemoveIncident} entityName="incident" />
      )}
    </>
  );
};

export default observer(IncidentDetailsHeader);

import styled from 'styled-components';

export const DetailsRowContainer = styled.div`
  flex-direction: column;
  margin-top: 16px;
  flex-basis: 50%;
`;

export const TitleText = styled.h4`
  color: #101d31;
  font: 14px/16px Roboto;
  font-weight: 500;
  letter-spacing: 0px;
  margin: 0;
  margin-bottom: 5px;
`;

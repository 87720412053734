import React from 'react';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Button } from 'vatix-ui/lib/components/Button';

import { HELP_CENTER_URL } from 'core/constants';

import { Header, Wrapper, UnsavedChanges } from './styles';
import { WrapperWithHeaderProps } from './types';

const WrapperWithHeader = ({
  title,
  helpCenterRedirect = false,
  unsavedChanges = false,
  children,
  flex,
}: WrapperWithHeaderProps): React.ReactElement => {
  const redirectToHelpCenter = (): void => {
    window.open(HELP_CENTER_URL, '_blank');
  };

  return (
    <Wrapper $flex={flex}>
      <Header>
        <div style={{ display: 'flex' }}>
          <p>{title}</p>
          {unsavedChanges && <UnsavedChanges>Unsaved changes</UnsavedChanges>}
        </div>
        {helpCenterRedirect && (
          <Button
            onClick={redirectToHelpCenter}
            variant="text"
            size="large"
            startIcon={<HelpOutlineOutlinedIcon />}
            style={{ paddingTop: 0, paddingBottom: 0, height: '100%' }}
          >
            Layout Editor Help
          </Button>
        )}
      </Header>
      {children}
    </Wrapper>
  );
};

export default WrapperWithHeader;

/* eslint-disable max-len */
import React from 'react';

export function ObjectManagerIcon(): React.ReactElement {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.36141 18.3182H1.66529C0.749381 18.3182 0 17.5688 0 16.6529V1.66529C0 0.749381 0.749381 0 1.66529 0H18.3182C19.2341 0 19.9835 0.749381 19.9835 1.66529V8.74278C19.9835 8.97592 19.8003 9.1591 19.5672 9.1591H18.7345C18.5014 9.1591 18.3182 8.97592 18.3182 8.74278V2.49794C18.3182 2.03166 17.9518 1.66529 17.4856 1.66529H2.49794C2.03166 1.66529 1.66529 2.03166 1.66529 2.49794V15.8203C1.66529 16.2865 2.03166 16.6529 2.49794 16.6529H6.36141C6.59455 16.6529 6.77774 16.8361 6.77774 17.0692V17.9019C6.77774 18.135 6.59455 18.3182 6.36141 18.3182Z"
        fill="#C0C7D3"
      />
      <path
        d="M15.0369 19.9833H11.5065C11.0402 19.9833 10.6738 19.6169 10.6738 19.1506V15.6202C10.6738 15.4037 10.7571 15.1873 10.9236 15.0374L15.0369 10.9241C15.3699 10.5911 15.8862 10.5911 16.2192 10.9241L19.7497 14.4545C20.0827 14.7876 20.0827 15.3038 19.7497 15.6369L15.6364 19.7502C15.4865 19.9 15.27 19.9999 15.0535 19.9999L15.0369 19.9833ZM12.439 18.318H14.5207C14.6372 18.318 14.7371 18.268 14.8204 18.2014L17.6847 15.3371C17.8512 15.1706 17.8512 14.9042 17.6847 14.7543L15.9195 12.9891C15.753 12.8225 15.4865 12.8225 15.3366 12.9891L12.4723 15.8534C12.4723 15.8534 12.3558 16.0366 12.3558 16.1531V18.2347C12.3558 18.2347 12.4057 18.3347 12.4557 18.3347L12.439 18.318Z"
        fill="#C0C7D3"
      />
      <path
        d="M15.354 8.52651H4.62952C4.16324 8.52651 3.79688 8.16015 3.79688 7.69387V4.36329C3.79688 3.897 4.16324 3.53064 4.62952 3.53064H15.3706C15.8369 3.53064 16.2033 3.897 16.2033 4.36329V7.69387C16.2033 8.16015 15.8369 8.52651 15.3706 8.52651H15.354ZM5.86184 6.86122H14.105C14.3382 6.86122 14.5214 6.67804 14.5214 6.4449V5.61225C14.5214 5.37911 14.3382 5.19593 14.105 5.19593H5.87849C5.64535 5.19593 5.46217 5.37911 5.46217 5.61225V6.4449C5.46217 6.67804 5.64535 6.86122 5.87849 6.86122H5.86184Z"
        fill="#C0C7D3"
      />
      <path
        d="M8.69283 15.1878H4.62952C4.16324 15.1878 3.79688 14.8214 3.79688 14.3551V11.0245C3.79688 10.5583 4.16324 10.1919 4.62952 10.1919H12.1233C12.3565 10.1919 12.5397 10.3751 12.5397 10.6082V11.4409C12.5397 11.674 12.3565 11.8572 12.1233 11.8572H5.46217V13.5225H8.70948C8.94262 13.5225 9.12581 13.7057 9.12581 13.9388V14.7714C9.12581 15.0046 8.94262 15.1878 8.70948 15.1878H8.69283Z"
        fill="#C0C7D3"
      />
      <defs>
        <clipPath id="clip0_19235_187052">
          <rect width="7" height="7" fill="white" transform="translate(12 5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveObjectManagerIcon(): React.ReactElement {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2502 12.5836L11.4168 13.417C11.4168 13.417 11.2335 13.5336 11.1168 13.5336H5.88347C5.65013 13.5336 5.4668 13.3503 5.4668 13.1336V12.3003C5.4668 12.0669 5.6668 11.8669 5.90013 11.8669H11.9668C12.3335 11.8669 12.5169 12.3169 12.2669 12.5836H12.2502Z"
        fill="white"
      />
      <path
        d="M14.5335 5.63353V6.46687C14.5335 6.68354 14.3502 6.86688 14.1169 6.86688H5.88347C5.65013 6.86688 5.4668 6.68354 5.4668 6.46687V5.63353C5.4668 5.4002 5.65013 5.2002 5.88347 5.2002H14.1169C14.3502 5.2002 14.5335 5.4002 14.5335 5.63353Z"
        fill="white"
      />
      <path
        d="M18.3335 0H1.66668C0.750006 0 0 0.750006 0 1.66668V16.6668C0 17.5835 0.750006 18.3335 1.66668 18.3335H9.40008C9.63341 18.3335 9.81675 18.1501 9.81675 17.9168V15.6168C9.81675 15.3835 9.63341 15.2001 9.40008 15.2001H4.63337C4.1667 15.2001 3.80003 14.8335 3.80003 14.3668V11.0334C3.80003 10.5834 4.1667 10.2001 4.63337 10.2001H14.7335C15.1168 9.96675 15.5501 9.83341 16.0168 9.83341C16.6668 9.83341 17.2668 10.0834 17.7335 10.5334L19.2835 12.1001C19.5502 12.3668 20.0002 12.1834 20.0002 11.8001V1.66668C20.0002 0.750006 19.2502 0 18.3335 0ZM16.2001 7.70006C16.2001 8.16673 15.8335 8.5334 15.3668 8.5334H4.63337C4.1667 8.5334 3.80003 8.16673 3.80003 7.70006V4.3667C3.80003 3.9167 4.1667 3.53336 4.63337 3.53336H15.3668C15.8335 3.53336 16.2001 3.9167 16.2001 4.3667V7.70006Z"
        fill="white"
      />
      <path
        d="M20.0011 15.4832C20.0011 15.6666 19.9344 15.8666 19.7678 16.0166L16.0177 19.7666C15.8844 19.9166 15.6844 19.9999 15.4844 19.9999H12.251C11.8344 19.9999 11.4844 19.6666 11.4844 19.2333V16.0166C11.4844 15.8166 11.5844 15.6166 11.7177 15.4832L13.6677 13.5332L14.5344 12.6666L15.4844 11.7165C15.6344 11.5665 15.8177 11.4999 16.0177 11.4999C16.0844 11.4999 16.1511 11.4999 16.2011 11.5332C16.3344 11.5499 16.4511 11.6165 16.5511 11.7165L19.7678 14.9332C19.9344 15.0999 20.0011 15.2832 20.0011 15.4832Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_19235_187052">
          <rect width="7" height="7" fill="white" transform="translate(12 5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function InactiveObjectManagerIcon(): React.ReactElement {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.36141 18.3182H1.66529C0.749381 18.3182 0 17.5688 0 16.6529V1.66529C0 0.749381 0.749381 0 1.66529 0H18.3182C19.2341 0 19.9835 0.749381 19.9835 1.66529V8.74278C19.9835 8.97592 19.8003 9.1591 19.5672 9.1591H18.7345C18.5014 9.1591 18.3182 8.97592 18.3182 8.74278V2.49794C18.3182 2.03166 17.9518 1.66529 17.4856 1.66529H2.49794C2.03166 1.66529 1.66529 2.03166 1.66529 2.49794V15.8203C1.66529 16.2865 2.03166 16.6529 2.49794 16.6529H6.36141C6.59455 16.6529 6.77774 16.8361 6.77774 17.0692V17.9019C6.77774 18.135 6.59455 18.3182 6.36141 18.3182Z"
        fill="#C0C7D3"
      />
      <path
        d="M15.0369 19.9833H11.5065C11.0402 19.9833 10.6738 19.6169 10.6738 19.1506V15.6202C10.6738 15.4037 10.7571 15.1873 10.9236 15.0374L15.0369 10.9241C15.3699 10.5911 15.8862 10.5911 16.2192 10.9241L19.7497 14.4545C20.0827 14.7876 20.0827 15.3038 19.7497 15.6369L15.6364 19.7502C15.4865 19.9 15.27 19.9999 15.0535 19.9999L15.0369 19.9833ZM12.439 18.318H14.5207C14.6372 18.318 14.7371 18.268 14.8204 18.2014L17.6847 15.3371C17.8512 15.1706 17.8512 14.9042 17.6847 14.7543L15.9195 12.9891C15.753 12.8225 15.4865 12.8225 15.3366 12.9891L12.4723 15.8534C12.4723 15.8534 12.3558 16.0366 12.3558 16.1531V18.2347C12.3558 18.2347 12.4057 18.3347 12.4557 18.3347L12.439 18.318Z"
        fill="#C0C7D3"
      />
      <path
        d="M15.354 8.52651H4.62952C4.16324 8.52651 3.79688 8.16015 3.79688 7.69387V4.36329C3.79688 3.897 4.16324 3.53064 4.62952 3.53064H15.3706C15.8369 3.53064 16.2033 3.897 16.2033 4.36329V7.69387C16.2033 8.16015 15.8369 8.52651 15.3706 8.52651H15.354ZM5.86184 6.86122H14.105C14.3382 6.86122 14.5214 6.67804 14.5214 6.4449V5.61225C14.5214 5.37911 14.3382 5.19593 14.105 5.19593H5.87849C5.64535 5.19593 5.46217 5.37911 5.46217 5.61225V6.4449C5.46217 6.67804 5.64535 6.86122 5.87849 6.86122H5.86184Z"
        fill="#C0C7D3"
      />
      <path
        d="M8.69283 15.1878H4.62952C4.16324 15.1878 3.79688 14.8214 3.79688 14.3551V11.0245C3.79688 10.5583 4.16324 10.1919 4.62952 10.1919H12.1233C12.3565 10.1919 12.5397 10.3751 12.5397 10.6082V11.4409C12.5397 11.674 12.3565 11.8572 12.1233 11.8572H5.46217V13.5225H8.70948C8.94262 13.5225 9.12581 13.7057 9.12581 13.9388V14.7714C9.12581 15.0046 8.94262 15.1878 8.70948 15.1878H8.69283Z"
        fill="#C0C7D3"
      />
      <defs>
        <clipPath id="clip0_19235_187052">
          <rect width="7" height="7" fill="white" transform="translate(12 5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

/* eslint-disable max-len */
import React, { SyntheticEvent } from 'react';

import SearchPopover from 'vatix-ui/lib/components/Chip/SearchPopover';

import { observer } from 'mobx-react';

import { useSearch } from 'vatix-ui/lib/utils/hooks/search';

import { DeviceTypeIDText, DeviceTypeNameText } from 'containers/Devices/components/DeviceTypeCell/styles';

import { useStore } from 'utils/hooks/store';

import Unassign from 'containers/Manage/components/MonitoredDevicesCell/components/UnassignItem';
import DropDownItem from 'containers/Manage/components/MonitoredDevicesCell/components/DropDownItem';
import API from 'utils/api';

import { SharedDeviceResponse } from 'utils/api/types';

import MobileChip from 'components/MobileToggle';

import { LicenseType, LicenseTypeName, Products } from 'core/constants';

import useUpdateUser, { getLicenseType } from 'stores/Manage/hooks/updateUser';

import AddFilled from 'components/Icons/AddFilled';
import Chip from 'components/Chip';
import AdditionalDeviceDropDownItem from 'containers/Manage/components/MonitoredDevicesCell/components/AdditionalDeviceDropDownItem';

import {
  Container,
  DeviceLabel,
  LicenseManagementLink,
  InsufficientLicensesBanner,
  DevicesContainer,
  AdditionalDevicesChipContainer,
} from './styles';
import { MonitoredDevicesCellProps } from './types';

const searchUnusedDevices: typeof API.searchDevices = (params) =>
  API.searchDevices({
    ...params,
    assigned: false,
  });

const MonitoredDevices: React.FunctionComponent<MonitoredDevicesCellProps> = ({
  rowData: { devices, showSafety, phoneNumber },
  rowData,
  showLicenseBanner,
  session,
  actions,
}) => {
  const { notification } = useStore();

  const [anchor, setAnchor] = React.useState<(EventTarget & Element) | null>(null);
  const [selectedDevice, setSelectedDevice] = React.useState<SharedDeviceResponse | null>(null);
  const [search, setSearch] = React.useState('');
  const [updating, setUpdating] = React.useState(false);
  const [licenseError, setLicenseError] = React.useState<LicenseType | null>(null);
  const updateUser = useUpdateUser();
  const [additionalDevicesAnchorEl, setAdditionalDevicesAnchorEl] = React.useState<(EventTarget & Element) | null>(
    null
  );

  const { results, searchQuery, loading } = useSearch(searchUnusedDevices, Boolean(anchor));

  const chipClick = (event: SyntheticEvent, device?: SharedDeviceResponse): void => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
    if (device) {
      setSelectedDevice(device);
    }
  };

  const close = async (): Promise<void> => {
    setAnchor(null);
    setSelectedDevice(null);
    setSearch('');
    await searchQuery('');
  };

  const update = async (data: { devices?: string[]; showSafety?: boolean }): Promise<void> => {
    try {
      setLicenseError(null);
      setUpdating(true);
      await updateUser(rowData.uuid, data, () => {}, actions ? actions.refreshData : async () => {});
    } catch (e) {
      // @ts-ignore
      const licenseType = getLicenseType(e);
      setLicenseError(licenseType);
    } finally {
      close();
      setUpdating(false);
    }
  };

  const unassign = (uuid?: string): void => {
    let newDevices = [];
    if (uuid) {
      newDevices = devices.filter((device) => device.uuid !== uuid).map((device) => device.uuid);
    } else {
      newDevices = devices.filter((device) => device.uuid !== selectedDevice?.uuid).map((device) => device.uuid);
    }
    update({ devices: newDevices });
  };

  const assign = async (item: { uuid: string }): Promise<void> => {
    if (rowData.alarmResponseProfile) {
      const newDevices = [
        ...devices.filter((device) => device.uuid !== selectedDevice?.uuid).map((device) => device.uuid),
        item.uuid,
      ];
      update({ devices: newDevices });
      if (session.user?.appCues) {
        window.Appcues.track('Device Assigned');
      }
    } else {
      notification.enqueueErrorSnackbar('Alarm Response Profile required');
      close();
    }
  };

  const toggleShowSafety = async (): Promise<void> => {
    if (rowData.alarmResponseProfile) {
      await update({ showSafety: !showSafety });
      if (!showSafety && session.user?.appCues) {
        window.Appcues.track('Assigned App Licence');
      }
    } else {
      notification.enqueueErrorSnackbar('Alarm Response Profile required');
    }
  };

  if (updating) {
    return <Container text>Updating devices...</Container>;
  }

  const onChange = async (s: string): Promise<void> => {
    setSearch(s);
    await searchQuery(s);
  };

  const additionalDevices = devices.slice(1, devices.length).map((device) => ({
    uuid: device.uuid,
    brand: device.type?.brand,
    model: device.type?.model,
    image: device.type?.image,
    name: device.customId,
  }));
  const openAdditionalDevices = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAdditionalDevicesAnchorEl(event.currentTarget);
  };
  const closeAdditionalDevices = (event?: React.MouseEvent<HTMLLIElement>): void => {
    setAdditionalDevicesAnchorEl(null);
    event?.stopPropagation();
  };

  return (
    <Container>
      {showLicenseBanner && licenseError && (
        <InsufficientLicensesBanner>
          Insufficient number of <b>{LicenseTypeName[licenseError]}</b> licenses. Visit
          <LicenseManagementLink href={`${Products.admin}/dashboard/users/`}>
            License Management
          </LicenseManagementLink>{' '}
          section.
        </InsufficientLicensesBanner>
      )}
      <MobileChip toggleShowSafety={toggleShowSafety} showSafety={showSafety} disabled={!phoneNumber && !showSafety} />
      <DevicesContainer>
        {devices.slice(0, 1).map((device) => (
          <Chip
            onClick={(event) => chipClick(event, device)}
            key={device.uuid}
            iconLeft={<img src={device.type?.image} alt={`${device.type?.brand} ${device.type?.model}`} />}
            label={
              <DeviceLabel>
                <DeviceTypeNameText id="device-type-name">{`${device.type?.brand} ${device.type?.model}`}</DeviceTypeNameText>
                <DeviceTypeIDText id="device-type-id">({device.customId})</DeviceTypeIDText>
              </DeviceLabel>
            }
            variant="outlined"
            colors={{
              background: '#FFFFFF',
              border: 'rgba(0, 0, 0, 0.12)',
            }}
          />
        ))}
        {additionalDevices.length ? (
          <AdditionalDevicesChipContainer
            onClick={(event) => {
              openAdditionalDevices(event);
            }}
          >
            <Chip
              label={`+${additionalDevices.length}`}
              variant="filled"
              size="small"
              colors={{
                background: 'rgba(0, 0, 0, 0.08)',
                foreground: 'rgba(0, 0, 0, 0.87)',
                hoverBackground: 'rgba(0, 0, 0, 0.12)',
              }}
              enableHover
              iconLeftGapWidth={8}
              iconRightGapWidth={8}
            />
            <SearchPopover
              // @ts-ignore
              id="popover-manage_devices"
              ListItem={AdditionalDeviceDropDownItem}
              handleClose={() => {
                closeAdditionalDevices();
              }}
              handleUpdate={async (el) => {
                unassign(el.uuid);
                closeAdditionalDevices();
              }}
              listData={additionalDevices}
              open={Boolean(additionalDevicesAnchorEl)}
              anchorEl={additionalDevicesAnchorEl as HTMLAnchorElement}
              loading={false}
              customMargin={{
                marginLeft: '16px',
                marginRight: '16px',
              }}
            />
          </AdditionalDevicesChipContainer>
        ) : null}
        <Chip
          aria-label="Add device"
          iconLeft={<AddFilled />}
          onClick={chipClick}
          variant="outlined"
          borderStyle="dashed"
          colors={{
            background: '#FFFFFF',
            border: 'rgba(0, 0, 0, 0.08)',
            iconLeftBackground: 'rgba(238, 238, 238, 1)',
          }}
          label={devices.length === 0 ? 'Add device' : undefined}
        />
      </DevicesContainer>
      <SearchPopover
        ListHeader={
          selectedDevice
            ? () => (
                <Unassign
                  onClick={() => {
                    unassign(selectedDevice.uuid);
                  }}
                />
              )
            : undefined
        }
        ListItem={DropDownItem}
        handleClose={close}
        handleUpdate={assign}
        listData={results}
        search={{
          onChange,
          placeholder: 'search here',
          value: search,
        }}
        anchorEl={anchor as HTMLAnchorElement}
        loading={loading}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </Container>
  );
};

export default observer(MonitoredDevices);

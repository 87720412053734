import React from 'react';

import { FieldDescription, SectionGrid } from './styles';

const EditEntityRow: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <>
    <SectionGrid item container xs={12} alignContent="flex-start" id={id}>
      <FieldDescription>{name}</FieldDescription>
    </SectionGrid>
    <SectionGrid item container xs={12}>
      {children}
    </SectionGrid>
  </>
);

export default EditEntityRow;

import styled from 'styled-components';

import Grid from '@mui/material/Grid';

export const SectionGrid = styled(Grid).attrs({
  classes: {
    item: 'grid-item',
  },
})`
  &.grid-item {
    align-items: center;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

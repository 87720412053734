import { EntityType, EntityValueType } from 'utils/api/types';

export type InitialValues = { [key: string]: EntityValueType };

export const transformInitialValues = (en: EntityType): InitialValues => {
  const initialValues: InitialValues = {};
  Object.keys(en).forEach((key) => {
    initialValues[key] = en[key].value;
  });
  return initialValues;
};

import React from 'react';
import { observer } from 'mobx-react';
import { reverse } from 'named-urls';
import { useAbac } from 'react-abac';

import NewSideBar from 'vatix-ui/lib/components/NewSideBar';
import { ProductType } from 'vatix-ui/lib/components/NewSideBar/types';
import { LockOutlined, NotificationsNoneOutlined, Lock, Notifications } from '@mui/icons-material';

import routes from 'core/routes';
import { useStore } from 'utils/hooks/store';

import { ActiveHomeIcon, HomeIcon } from './Icons/HomeIcon';
import { ActiveAllInboxIcon, ActiveInboxIcon, AllInboxIcon, InboxIcon } from './Icons/InboxIcon';
import { ActiveListIcon, ListIcon } from './Icons/ListIcon';
import { ActionIcon, ActiveActionIcon } from './Icons/ActionsIcon';
import { ActiveIncidentIcon, IncidentIcon } from './Icons/IncidentsIcon';
import { ActiveProtectorIcon, ProtectorIcon } from './Icons/ProtectorIcon';
import { ActiveAuditsIcon, AuditsIcon } from './Icons/AuditsIcon';
import { ActiveAnalyticsIcon, AnalyticsIcon } from './Icons/AnalyticsIcon';
import { ActiveUserIcon, UserIcon } from './Icons/UserIcon';
import { ActiveMyActionsIcon, MyActionsIcon } from './Icons/MyActionsIcon';
import { ActiveManageIcon, ManageIcon } from './Icons/ManageIcon';
import { ActiveDevicesIcon, DevicesIcon } from './Icons/DevicesIcon';
import { ActiveUsageIcon, UsageIcon } from './Icons/UsageIcon';
import { ActiveARPIcon, ARPIcon } from './Icons/ARPIcon';
import { ActiveFormIcon, FormIcon } from './Icons/FromsIcon';
import { ActiveFormTemplateIcon, FormTemplateIcon } from './Icons/FormTemplatesIcon';
import { ActiveTeamIcon, TeamIcon } from './Icons/TeamIcon';
import { ActiveUsersIcon, UsersIcon } from './Icons/UsersIcon';
import { ActiveLicensesIcon, LicensesIcon } from './Icons/LicensesIcon';
import { ProfileSettingsSubItemsIconSX } from './styles';
import { ActiveCompanySettingsIcon, CompanySettingsIcon } from './Icons/CompanySettingsIcon';
import { SubItemsType } from './types';
import { ActiveMoreAppsIcon, MoreAppsIcon } from './Icons/MoreAppsIcon';
import { ActiveMapIcon, MapIcon } from './Icons/MapIcon';
import { SideBarPermissions } from './permissions/types';
import { ActiveSitesIcon, SitesIcon } from './Icons/SitesIcon';
import { ActiveObjectManagerIcon, ObjectManagerIcon } from './Icons/ObjectManagerIcon';

const SideBar = (): React.ReactElement => {
  const {
    routing,
    session: { user, hasOrganisationLogo, loadOrganisationLogo, hasAccessToSitesModule },
    ui: { subSideBarOpen },
    inbox,
    incidentDetails: { contentOfIncidentWasEdited },
  } = useStore();
  const licenses = user ? user.licenses : {};
  const { userHasPermissions } = useAbac();

  React.useEffect(() => {
    loadOrganisationLogo();
  }, [hasOrganisationLogo]);

  React.useEffect(() => {
    if (inbox.initialSync === undefined) {
      inbox.loadInitialData();
    }
  }, []);

  const Products: ProductType[] = [];

  if (userHasPermissions(SideBarPermissions.HOME_PAGE_VISIBLE)) {
    Products.push({
      name: 'Home',
      Icon: <HomeIcon />,
      ActiveIcon: <ActiveHomeIcon />,
      to: routes.dashboard.home.toString(),
      isSelected: routing.location.pathname === routes.dashboard.home.toString(),
    });
  }

  if (userHasPermissions(SideBarPermissions.INBOX_VISIBLE, licenses)) {
    Products.push({
      to: routes.dashboard.notifications,
      isSelected: routing.location.pathname.startsWith(routes.dashboard.notifications),
      name: 'Inbox',
      Icon: <InboxIcon notDismissedNotificationsCount={inbox.notDismissedNotificationsCount} />,
      ActiveIcon: <ActiveInboxIcon notDismissedNotificationsCount={inbox.notDismissedNotificationsCount} />,
      subItems: [
        {
          name: 'All',
          Icon: <AllInboxIcon />,
          ActiveIcon: <ActiveAllInboxIcon />,
          to: routes.dashboard.notifications,
          isSelected: routing.location.pathname.startsWith(routes.dashboard.notifications),
        },
      ],
    });
  }

  if (userHasPermissions(SideBarPermissions.ACTIONS_VISIBLE, licenses)) {
    Products.push({
      name: 'Actions',
      Icon: <ActionIcon />,
      ActiveIcon: <ActiveActionIcon />,
      to: routes.dashboard.tasks.all.toString(),
      isSelected: routing.location.pathname.startsWith(routes.dashboard.tasks.toString()),
      subItems: [
        {
          name: 'Tasks',
          Icon: <ListIcon />,
          ActiveIcon: <ActiveListIcon />,
          to: routes.dashboard.tasks.all.toString(),
          isSelected:
            routing.location.pathname.startsWith(routes.dashboard.tasks.all.toString()) &&
            !routing.location.pathname.startsWith(routes.dashboard.tasks.my),
        },
        {
          name: 'My Tasks',
          Icon: <MyActionsIcon />,
          ActiveIcon: <ActiveMyActionsIcon />,
          to: routes.dashboard.tasks.my,
          isSelected: routing.location.pathname.startsWith(routes.dashboard.tasks.my),
        },
      ],
    });
  }

  if (userHasPermissions(SideBarPermissions.INCIDENTS_VISIBLE, licenses)) {
    Products.push({
      name: 'Incidents',
      Icon: <IncidentIcon />,
      ActiveIcon: <ActiveIncidentIcon />,
      to: routes.dashboard.incidents.toString(),
      isSelected:
        routing.location.pathname.startsWith(routes.dashboard.incidents.toString()) &&
        !routing.location.pathname.startsWith(routes.dashboard.incidents.report.toString()),
      subItems: [
        {
          name: 'Overview',
          Icon: <ListIcon />,
          ActiveIcon: <ActiveListIcon />,
          to: routes.dashboard.incidents.toString(),
          isSelected:
            routing.location.pathname.startsWith(routes.dashboard.incidents.toString()) &&
            !routing.location.pathname.startsWith(routes.dashboard.incidents.report.toString()),
        },
      ],
    });
  }

  if (userHasPermissions(SideBarPermissions.LONE_WORKING_VISIBLE, licenses)) {
    const LoneWorkingSubItems = [
      {
        name: 'Overview',
        Icon: <ListIcon />,
        ActiveIcon: <ActiveListIcon />,
        to: routes.dashboard.alarms.red.toString(),
        isSelected:
          routing.location.pathname.startsWith(routes.dashboard.alarms.red.toString()) ||
          routing.location.pathname.startsWith(routes.dashboard.alarms.amber.toString()),
      },
    ];

    if (userHasPermissions(SideBarPermissions.LONE_WORKING_MANAGE_VISIBLE)) {
      LoneWorkingSubItems.push({
        name: 'Manage',
        Icon: <ManageIcon />,
        ActiveIcon: <ActiveManageIcon />,
        to: routes.dashboard.alarms.manage.toString(),
        isSelected: routing.location.pathname.startsWith(routes.dashboard.alarms.manage.toString()),
      });
    }

    if (userHasPermissions(SideBarPermissions.LONE_WORKING_DEVICES_VISIBLE)) {
      LoneWorkingSubItems.push({
        name: 'Devices',
        Icon: <DevicesIcon />,
        ActiveIcon: <ActiveDevicesIcon />,
        to: routes.dashboard.alarms.devices.toString(),
        isSelected: routing.location.pathname.startsWith(routes.dashboard.alarms.devices.toString()),
      });
    }

    if (userHasPermissions(SideBarPermissions.LONE_WORKING_USAGE_VISIBLE)) {
      LoneWorkingSubItems.push({
        name: 'Usage',
        Icon: <UsageIcon />,
        ActiveIcon: <ActiveUsageIcon />,
        to: routes.dashboard.alarms.reports.toString(),
        isSelected: routing.location.pathname.startsWith(routes.dashboard.alarms.reports.toString()),
      });
    }

    if (userHasPermissions(SideBarPermissions.LONE_WORKING_ARPS_VISIBLE)) {
      LoneWorkingSubItems.push({
        name: 'Alarm Response Profiles',
        Icon: <ARPIcon />,
        ActiveIcon: <ActiveARPIcon />,
        to: routes.dashboard.alarms.escalationProfiles.toString(),
        isSelected: routing.location.pathname.startsWith(routes.dashboard.alarms.escalationProfiles.toString()),
      });
    }

    if (userHasPermissions(SideBarPermissions.LONE_WORKING_MAP_VISIBLE, user)) {
      LoneWorkingSubItems.push({
        name: 'Map',
        Icon: <MapIcon />,
        ActiveIcon: <ActiveMapIcon />,
        to: routes.dashboard.alarms.activityMap.toString(),
        isSelected: routing.location.pathname.startsWith(routes.dashboard.alarms.activityMap.toString()),
      });
    }

    Products.push({
      name: 'Lone Working',
      Icon: <ProtectorIcon />,
      ActiveIcon: <ActiveProtectorIcon />,
      to: routes.dashboard.alarms.red.toString(),
      isSelected: routing.location.pathname.startsWith(routes.dashboard.alarms.toString()),
      subItems: LoneWorkingSubItems,
    });
  }

  if (userHasPermissions(SideBarPermissions.AUDITS_VISIBLE, licenses)) {
    Products.push({
      name: 'Audits',
      Icon: <AuditsIcon />,
      ActiveIcon: <ActiveAuditsIcon />,
      to: routes.dashboard.audits.forms.toString(),
      isSelected: routing.location.pathname.startsWith(routes.dashboard.audits.toString()),
      subItems: [
        {
          name: 'Forms',
          Icon: <FormIcon />,
          ActiveIcon: <ActiveFormIcon />,
          to: routes.dashboard.audits.forms.toString(),
          isSelected: routing.location.pathname.startsWith(routes.dashboard.audits.forms.toString()),
        },
        {
          name: 'Form Templates',
          Icon: <FormTemplateIcon />,
          ActiveIcon: <ActiveFormTemplateIcon />,
          to: routes.dashboard.audits.templates.toString(),
          isSelected: routing.location.pathname.startsWith(routes.dashboard.audits.templates.toString()),
        },
      ],
    });
  }

  if (userHasPermissions(SideBarPermissions.ANALYTICS_VISIBLE, { licenses, user })) {
    const dashboards: SubItemsType = [];
    const analyticIcons = (dashboard: string): JSX.Element => {
      switch (dashboard) {
        case 'audits':
          return <AuditsIcon />;
        case 'incidents':
          return <IncidentIcon />;
        case 'protector':
          return <ProtectorIcon />;
        default:
          return <IncidentIcon />;
      }
    };

    const analyticActiveIcons = (dashboard: string): JSX.Element => {
      switch (dashboard) {
        case 'audits':
          return <AuditsIcon />;
        case 'incidents':
          return <IncidentIcon />;
        case 'protector':
          return <ProtectorIcon />;
        default:
          return <IncidentIcon />;
      }
    };

    const dashboardName = (dashboard: string): string =>
      dashboard === 'protector' ? 'Lone Working' : `${dashboard[0].toUpperCase() + dashboard.slice(1).toLowerCase()}`;

    Object.keys(user?.dashboards ? user.dashboards : {}).map(
      (dashboard) =>
        user?.dashboards?.[dashboard] &&
        user?.dashboards?.[dashboard].length > 0 &&
        dashboards.push({
          name: dashboardName(dashboard),
          to: reverse(routes.dashboard.analytics.details, { dashboardId: user?.dashboards?.[dashboard][0].uuid || '' }),
          isSelected: routing.location.pathname.startsWith(
            reverse(routes.dashboard.analytics.details, { dashboardId: user?.dashboards?.[dashboard][0].uuid || '' })
          ),
          Icon: analyticIcons(dashboard),
          ActiveIcon: analyticActiveIcons(dashboard),
          subItems:
            user?.dashboards?.[dashboard]?.map(({ uuid, name }, index) => ({
              uuid,
              name,
              to: reverse(routes.dashboard.analytics.details, {
                dashboardId: user?.dashboards?.[dashboard][index].uuid || '',
              }),
              isSelected: routing.location.pathname.startsWith(
                reverse(routes.dashboard.analytics.details, {
                  dashboardId: user?.dashboards?.[dashboard][index].uuid || '',
                })
              ),
            })) ?? [],
        })
    );

    Products.push({
      name: 'Analytics',
      Icon: <AnalyticsIcon />,
      ActiveIcon: <ActiveAnalyticsIcon />,
      to: dashboards?.[0]?.subItems?.[0].to || '',
      isSelected: routing.location.pathname.startsWith(routes.dashboard.analytics.toString()),
      subItems: dashboards,
    });
  }

  if (user && hasAccessToSitesModule(user)) {
    Products.push({
      name: 'Sites',
      Icon: <SitesIcon />,
      ActiveIcon: <ActiveSitesIcon />,
      to: routes.dashboard.sites.toString(),
      isSelected: routing.location.pathname.startsWith(routes.dashboard.sites.toString()),
      subItems: [
        {
          name: 'Overview',
          Icon: <ListIcon />,
          ActiveIcon: <ActiveListIcon />,
          to: routes.dashboard.sites.toString(),
          isSelected: routing.location.pathname.startsWith(routes.dashboard.sites.toString()),
        },
      ],
    });
  }

  if (userHasPermissions(SideBarPermissions.ADMIN_VISIBLE)) {
    Products.push({
      name: 'Admin',
      Icon: <UserIcon />,
      ActiveIcon: <ActiveUserIcon />,
      to: routes.dashboard.admin.users.toString(),
      isSelected:
        routing.location.pathname.startsWith(routes.dashboard.admin.toString()) ||
        routing.location.pathname.startsWith(routes.dashboard.admin.licenses.toString()),
      subItems: [
        {
          name: 'Users',
          Icon: <UsersIcon />,
          ActiveIcon: <ActiveUsersIcon />,
          to: routes.dashboard.admin.users.toString(),
          isSelected: routing.location.pathname.startsWith(routes.dashboard.admin.users.toString()),
        },
        {
          name: 'Teams',
          Icon: <TeamIcon />,
          ActiveIcon: <ActiveTeamIcon />,
          to: routes.dashboard.admin.teams.toString(),
          isSelected: routing.location.pathname.startsWith(routes.dashboard.admin.teams.toString()),
        },
        {
          name: 'Licences',
          Icon: <LicensesIcon />,
          ActiveIcon: <ActiveLicensesIcon />,
          to: routes.dashboard.admin.licenses.toString(),
          isSelected: routing.location.pathname.startsWith(routes.dashboard.admin.licenses.toString()),
        },
        {
          name: 'Company Settings',
          Icon: <CompanySettingsIcon />,
          ActiveIcon: <ActiveCompanySettingsIcon />,
          to: routes.dashboard.admin.companySettings.toString(),
          isSelected: routing.location.pathname.startsWith(routes.dashboard.admin.companySettings.toString()),
        },
      ],
    });
  }

  const ProfileSettingsSubItems = [
    {
      name: 'Security',
      Icon: <LockOutlined htmlColor="rgba(255, 255, 255, 0.70)" sx={ProfileSettingsSubItemsIconSX} />,
      ActiveIcon: <Lock htmlColor="#FFF" sx={ProfileSettingsSubItemsIconSX} />,
      to: routes.dashboard.profileSettings.security.toString(),
      isSelected: routing.location.pathname.startsWith(routes.dashboard.profileSettings.security.toString()),
    },
  ];

  if (user?.licenses.protectorIncidents || user?.licenses.workflows) {
    ProfileSettingsSubItems.unshift({
      name: 'Notifications',
      Icon: <NotificationsNoneOutlined htmlColor="rgba(255, 255, 255, 0.70)" sx={ProfileSettingsSubItemsIconSX} />,
      ActiveIcon: <Notifications htmlColor="#FFF" sx={ProfileSettingsSubItemsIconSX} />,
      to: routes.dashboard.profileSettings.notifications.toString(),
      isSelected: routing.location.pathname.startsWith(routes.dashboard.profileSettings.notifications.toString()),
    });
  }

  Products.push({
    name: 'Profile Settings',
    Icon: <></>,
    ActiveIcon: <></>,
    to: routes.dashboard.profileSettings.notifications.toString(),
    isSelected: routing.location.pathname.startsWith(routes.dashboard.profileSettings.toString()),
    hideIcon: true,
    subItems: ProfileSettingsSubItems,
  });

  if (user && user.showObjectManager) {
    Products.push({
      name: 'Object Manager',
      Icon: <ObjectManagerIcon />,
      ActiveIcon: <ActiveObjectManagerIcon />,
      to: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName: 'sites' }),
      isSelected: routing.location.pathname.startsWith(routes.dashboard.objectManager.toString()),
      subItems: [
        {
          name: 'Sites',
          Icon: <SitesIcon />,
          ActiveIcon: <ActiveSitesIcon />,
          to: reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName: 'sites' }),
          isSelected: routing.location.pathname.startsWith(
            routes.dashboard.objectManager.details.fieldManager.toString()
          ),
          subItems: [
            {
              name: 'Field Manager',
              to: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName: 'sites' }),
              isSelected: routing.location.pathname.startsWith(
                reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName: 'sites' })
              ),
            },
            {
              name: 'Layout Editor',
              to: reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName: 'sites' }),
              isSelected: routing.location.pathname.startsWith(
                reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName: 'sites' })
              ),
            },
          ],
        },
      ],
    });
  }

  if (userHasPermissions(SideBarPermissions.MORE_APPS_VISIBLE, licenses)) {
    Products.push({
      name: 'More Apps',
      Icon: <MoreAppsIcon />,
      ActiveIcon: <ActiveMoreAppsIcon />,
      to: routes.dashboard.moreApps.toString(),
      isSelected: routing.location.pathname.startsWith(routes.dashboard.moreApps.toString()),
    });
  }

  return (
    <NewSideBar
      Products={Products}
      current={routing.location.pathname}
      collapsed={!subSideBarOpen}
      hasOrganisationLogo={hasOrganisationLogo}
      navigationBlocked={contentOfIncidentWasEdited}
    />
  );
};

export default observer(SideBar);

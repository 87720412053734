import React from 'react';
import { observer } from 'mobx-react';

import { Button } from 'vatix-ui/lib/components/Button';

import AddIcon from '@mui/icons-material/Add';
import { reverse } from 'named-urls';

import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { useRouteMatch } from 'react-router-dom';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import { ColumnDefinition } from 'components/Grid/types';
import Grid, { defaultOperators, defaultRenderers } from 'components/Grid';

import { TaskStatus } from 'core/constants';

import { StyledTaskChip, StyledTaskDueDate } from 'containers/Tasks/components/TasksTable/styles';

import { Header, Content } from './styles';
import AddActionModal from './AddActionModal';

const basicColumns = ([
  {
    field: 'name',
    headerName: 'Name',
    nativeFilter: false,
  },
  {
    field: 'assigned',
    headerName: 'Assigned',
    renderCell: defaultRenderers.user,
    filterOperators: defaultOperators.user(),
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    nativeFilter: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: ({ value }: { value: TaskStatus }) => <StyledTaskChip status={value} size="small" />,
  },
  {
    field: 'dueDate',
    headerName: 'Due date',
    renderCell: ({ value }: { value: string }) => <StyledTaskDueDate date={value} />,
    filterOperators: defaultOperators.date,
    nativeFilter: false,
  },
] as unknown) as ColumnDefinition[];

const RelatedTab: React.FunctionComponent = () => {
  const { routing } = useStore();

  const onRowClick: GridEventListener<'rowClick'> = (task: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.tasks.details, { taskId: task.row.uuid }));
  };
  const refreshRef = React.useRef<Function | null>(null);

  const match = useRouteMatch();
  const { formId } = match.params as { formId: string };
  const [openModal, setOpenModal] = React.useState(false);
  const onRefreshCallback = (callback: Function): void => {
    refreshRef.current = callback;
  };

  return (
    <Content>
      <Header>
        <h1>Related Tasks</h1>

        <Button
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <AddIcon style={{ width: 20, height: 20, marginRight: '8px' }} />
          New Task
        </Button>
        <AddActionModal
          openModal={openModal}
          onClose={(created: boolean) => {
            setOpenModal(false);
            if (refreshRef.current && created) {
              refreshRef.current();
            }
          }}
        />
      </Header>
      <div style={{ margin: '20px' }}>
        <Grid
          onRefreshCallback={onRefreshCallback}
          basicColumns={basicColumns}
          entity="action"
          onRowClick={onRowClick}
          dataURL={`tasks/?inspection=${formId}`}
          disableColumnSelector
          showHeader={false}
          disableColumnMenu
        />
      </div>
    </Content>
  );
};

export default observer(RelatedTab);

import styled from 'styled-components';

export const EmptyContainer = styled.p`
  display: flex;
  padding: 6px;
  align-items: center;
  margin: 0px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

import React from 'react';
import { observer } from 'mobx-react';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { BackgroundInformationSections } from '../SiteDetailsTab/styles';
import IncidentsRelatedToSites from './components/IncidentsRelatedToSites';
import TasksRelatedToSites from './components/TasksRelatedToSites';
// TODO: uncomment this line when backend is ready
// import AuditsRelatedToSites from './components/AuditsRelatedToSites';

const SiteRelatedTab: React.FunctionComponent<{ onError: (error: VatixError) => void }> = ({ onError }) => (
  <BackgroundInformationSections>
    <IncidentsRelatedToSites onError={onError} />
    <TasksRelatedToSites onError={onError} />
    {/* <AuditsRelatedToSites onError={onError} /> */}
  </BackgroundInformationSections>
);

export default observer(SiteRelatedTab);

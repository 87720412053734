export interface TwoFactorAuthSetupFormProps {
  onClose: () => void;
  onInitialVerificationCodeSent: () => void;
}

export enum TwoFactorAuthSetupStep {
  VerifyPhoneNumber = 'verify_phone_number',
  ConfirmVerificationCode = 'confirm_verifications_code',
}

export interface ConfirmVerificationCodeValues {
  verificationCode: string;
}

export interface VerifyPhoneNumberValues {
  phoneNumber: string;
}

export type TwoFactorAuthSetupFormValues = VerifyPhoneNumberValues & ConfirmVerificationCodeValues;

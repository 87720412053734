import React from 'react';

import { Grid } from '@mui/material';
import { formatDuration } from 'vatix-ui/lib/utils/formatters/time';

import { Button } from 'vatix-ui/lib/components/Button';

import AlarmSource from 'components/AlarmSource';
import AlarmStatusChip from 'components/Chips/AlarmStatusChip';

import UserField from 'containers/IncidentDetails/components/UserField';

import PersonalDetails from 'components/PersonalDetails';

import { EmptyValue } from 'containers/IncidentDetails/components/CustomFieldDisplayValue/styles';

import { formatDateForDisplay } from 'utils/formatters/time';

import { TriggerTypeName, UserRole } from 'core/constants';

import { useStore } from 'utils/hooks/store';

import Row from '../Row';
import DividerRow from '../DividerRow';

import { Value, SectionContainer, BackgroundInformationSections } from './styles';

import { AlarmCardProps } from './types';
import AlarmLocation from '../AlarmLocation';

const AlarmCard: React.FunctionComponent<AlarmCardProps> = ({
  details: { activated, alertStatus, duration, device, user, uuid, triggerType },
}) => {
  const [showMedicalDetails, setShowMedicalDetails] = React.useState(false);
  const { session } = useStore();
  const toggleMedicalDetails = (): void => {
    setShowMedicalDetails((prev) => !prev);
  };

  return (
    <BackgroundInformationSections key={`Alarm-details-${uuid}`}>
      <SectionContainer>
        <Grid container spacing={0}>
          <DividerRow name="Personal Details" />

          <Row name="Started by" id="started-by">
            <UserField userId={(user.uuid as string).replace('user:', '')} />
          </Row>
          <Row name="Phone number" id="user-phone-number">
            {user.phoneNumber ? (
              <Value id="user-phone-number-value">{user.phoneNumber}</Value>
            ) : (
              <EmptyValue>-</EmptyValue>
            )}
          </Row>
          <Row name="Date of birth" id="user-date-of-birth">
            {user.personalDetails?.birthDate ? (
              <Value id="user-date-of-birth-value">{user.personalDetails?.birthDate}</Value>
            ) : (
              <EmptyValue>-</EmptyValue>
            )}
          </Row>
          <Row name="Memorable word" id="memorable-word-row">
            {user.personalDetails?.verificationWord ? (
              <Value id="memorable-word-row-value">{user.personalDetails?.verificationWord}</Value>
            ) : (
              <EmptyValue id="memorable-word-row-empty-value">-</EmptyValue>
            )}
          </Row>

          {showMedicalDetails ? <PersonalDetails details={user} /> : null}
          {(session.user?.role === UserRole.ArcOperator || session.user?.role === UserRole.Admin) && (
            <Button variant="text" onClick={toggleMedicalDetails} style={{ padding: 0 }}>
              {showMedicalDetails ? 'Show less...' : 'Show more...'}
            </Button>
          )}
          <DividerRow name="Alarm details" />
          <Row name="Duration" id="alarm-duration">
            {duration ? (
              <Value id="alarm-duration-value">{formatDuration(duration)}</Value>
            ) : (
              <EmptyValue>-</EmptyValue>
            )}
          </Row>
          <Row name="Status" id="alarm-status">
            <AlarmStatusChip status={alertStatus} />
          </Row>
          <Row name="Raised at" id="alarm-time">
            <Value id="alarm-time-value">{formatDateForDisplay(activated)}</Value>
          </Row>
          <Row name="Source" id="alarm-source">
            {device ? <AlarmSource device={device} /> : <EmptyValue>-</EmptyValue>}
          </Row>
          <Row name="Type" id="alarm-type">
            {device ? <Value id="alarm-type-value">{TriggerTypeName[triggerType]}</Value> : <EmptyValue>-</EmptyValue>}
          </Row>

          {device?.uuid ? <AlarmLocation alarmId={uuid} /> : null}
        </Grid>
      </SectionContainer>
    </BackgroundInformationSections>
  );
};

export default AlarmCard;

import React from 'react';

import { observer } from 'mobx-react';

import { GridActionsCellItem } from '@mui/x-data-grid';

import { Popover } from '@mui/material';

import { MoreVert } from '@mui/icons-material';

import { reverse } from 'named-urls';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import { UserRole } from 'core/constants';

import { IncidentResponse } from 'utils/api/types';

import { ColumnActions } from 'components/Grid/types';

import { MenuItem } from './styles';
import RemoveEntityModal from '../../../../components/RemoveEntityModal/RemoveEntityModal';

const ActionCell: React.FunctionComponent<{ rowData: IncidentResponse; actions: ColumnActions }> = ({
  rowData,
  actions,
}) => {
  const { incidentDetails, session, routing } = useStore();

  const userRole = session.user?.role as UserRole;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
  };

  const handleEditClick = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
    routing.push(reverse(routes.dashboard.incidents.details, { incidentId: rowData.uuid }));
  };

  const handleRemoveClick = async (): Promise<void> => {
    setOpenModal(true);
    setAnchorEl(null);
  };

  const closeModal = (): void => {
    setOpenModal(false);
  };

  const handleRemoveIncident = async (): Promise<void> => {
    await incidentDetails.deleteIncident(rowData.uuid);
    await actions.refreshData();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <GridActionsCellItem icon={<MoreVert />} label="" color="inherit" onClick={handleOpen} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          id="edit-button"
          onClick={async (e) => {
            handleEditClick(e);
          }}
        >
          {!session?.user?.readOnly ? 'Edit' : 'View'}
        </MenuItem>
        {[UserRole.Admin, UserRole.AccountManager, UserRole.Manager].includes(userRole) && !session?.user?.readOnly && (
          <MenuItem id="remove-button" onClick={handleRemoveClick}>
            Delete
          </MenuItem>
        )}
      </Popover>
      {openModal && (
        <RemoveEntityModal onClose={closeModal} handleRemoveEntity={handleRemoveIncident} entityName="incident" />
      )}
    </>
  );
};

export default observer(ActionCell);

import React from 'react';

import { observer } from 'mobx-react';

import { GridActionsCellItem } from '@mui/x-data-grid';

import { Popover } from '@mui/material';

import { MoreVert } from '@mui/icons-material';

import { reverse } from 'named-urls';

import { useHistory } from 'react-router-dom';

import routes from 'core/routes';

import { ColumnActions } from 'components/Grid/types';

import { Site } from 'containers/Sites/types';

import RemoveEntityModal from 'components/RemoveEntityModal';

import { useStore } from 'utils/hooks/store';

import { MenuItem } from './styles';

const ActionCell: React.FunctionComponent<{ rowData: Site; actions: ColumnActions }> = ({ rowData, actions }) => {
  const history = useHistory();

  const { siteDetails, notification } = useStore();

  const [deletionModalOpen, setDeletionModalOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
  };

  const handleCloseModal = (): void => {
    if (!deleting) {
      setDeletionModalOpen(false);
      setAnchorEl(null);
    }
  };

  const handleEditClick = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
    history.push(reverse(routes.dashboard.sites.details, { siteId: rowData.uuid }));
  };

  const onDelete = async (): Promise<void> => {
    const deleted = await siteDetails.deleteSite(rowData.uuid);
    if (deleted) {
      notification.enqueueSuccessSnackbar('Site deleted successfully');
      await actions.refreshData();
      setDeleting(false);
    } else {
      notification.enqueueErrorSnackbar('The site could not be deleted. Something went wrong.');
    }
  };

  return (
    <>
      <GridActionsCellItem
        icon={<MoreVert />}
        label=""
        color="inherit"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem id="remove-button" onClick={handleEditClick}>
          Edit
        </MenuItem>
        <MenuItem id="remove-button" onClick={() => setDeletionModalOpen(true)}>
          Delete
        </MenuItem>
        {deletionModalOpen && (
          <RemoveEntityModal onClose={handleCloseModal} handleRemoveEntity={onDelete} entityName="site" />
        )}
      </Popover>
    </>
  );
};

export default observer(ActionCell);

import React from 'react';

import { listRenderer } from 'components/Grid/renderers/GridListRenderer';
import { ListRendererType } from 'components/Grid/renderers/GridListRenderer/types';
import TaskLinkedToCell from 'containers/Tasks/components/TaskLinkedToCell';
import { NotLinked } from 'containers/Tasks/components/TaskLinkedToCell/styles';
import { ColumnDefinition } from 'components/Grid/types';
import { LinksType } from 'utils/api/types';

const SiteGridColumn = ({
  defaultVisibility: false,
  field: 'links',
  nativeFilter: 'site',
  headerName: 'Site',
  renderCell: listRenderer((TaskLinkedToCell as unknown) as ListRendererType, 1, undefined, {
    empty: <NotLinked>Not Linked</NotLinked>,
  }),
  valueGetter: ({ value, row }: { value: { uuid: string; name: string } | null; row: { links: Array<LinksType> } }) => {
    if (!row.links) return [];
    return value !== null
      ? row.links.filter((link) => link.entity === 'site')
      : row.links.find((link) => link.entity === 'site');
  },
} as unknown) as ColumnDefinition;

export default SiteGridColumn;

import React from 'react';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import { reverse } from 'named-urls';

import { IconButton, MenuItem } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import { useHistory } from 'react-router-dom';

import routes from 'core/routes';

import { EntityType } from 'utils/api/types';

import UserRow from 'components/UserRow';

import { useStore } from 'utils/hooks/store';

import RemoveEntityModal from 'components/RemoveEntityModal';

import { SiteDetailsHeaderWrapper, StyledPopover, ButtonWrapper } from './styles';

const SiteDetailsHeader: React.FC<{
  entity: EntityType | undefined;
  createdAt: string;
  uuid: string;
}> = ({ entity, createdAt, uuid }) => {
  const history = useHistory();
  const { siteDetails, notification } = useStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

  const onDelete = async (): Promise<void> => {
    const deleted = await siteDetails.deleteSite(uuid);
    if (deleted) {
      history.push(reverse(routes.dashboard.sites.toString()));
      notification.enqueueSuccessSnackbar('Site deleted successfully');
      setOpenDeleteModal(false);
    } else {
      notification.enqueueErrorSnackbar('The site could not be deleted. Something went wrong.');
    }
  };

  const openModal = (): void => {
    setAnchorEl(null);
    setOpenDeleteModal(true);
  };

  const closeModal = (): void => {
    setOpenDeleteModal(false);
  };

  if (!entity) {
    return <></>;
  }

  return (
    <>
      <MainHeader
        title={entity.siteName.value as string}
        breadcrumbs={[
          {
            label: 'Sites',
            link: routes.dashboard.sites.toString(),
          },
          {
            label: 'Details',
            link: reverse(routes.dashboard.sites.details, { siteID: uuid as string }),
          },
        ]}
        action={
          <>
            <ButtonWrapper>
              <IconButton onClick={handleClick}>
                <MoreVertIcon htmlColor="rgba(0, 0, 0, 0.54)" />
              </IconButton>
            </ButtonWrapper>
            <StyledPopover
              data-testid="site-details-header-popover"
              id="popover-site-details"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuItem id="remove-button" onClick={openModal}>
                Delete
              </MenuItem>
            </StyledPopover>

            {openDeleteModal && (
              <RemoveEntityModal onClose={closeModal} handleRemoveEntity={onDelete} entityName="site" />
            )}
          </>
        }
      />
      <SiteDetailsHeaderWrapper>
        <div id="site-details-header-name">
          <h1>Name</h1>
          <p>{entity.siteName.value as string}</p>
        </div>
        <div id="site-details-header-manager">
          <h1>Manager</h1>
          <>
            {entity.siteManager.value ? (
              <UserRow user={(entity.siteManager.value as unknown) as UuidableName} />
            ) : (
              'N/A'
            )}
          </>
        </div>
        <div id="site-details-header-created">
          <h1>Created</h1>
          <p>{formatDateTime(createdAt)}</p>
        </div>
      </SiteDetailsHeaderWrapper>
    </>
  );
};

export default SiteDetailsHeader;

import React from 'react';

import { observer } from 'mobx-react';
import AddCommentActivities from 'vatix-ui/lib/components/AddCommentActivities';

import API from 'utils/api';

const SitesDetailsActivitiesFooter: React.FunctionComponent = (): React.ReactElement => {
  // TODO: create a store for site activities
  const activities = {
    isLoading: false,
    hasMore: false,
    api: undefined,
    activities: [],
    loadActivities: () => {},
    reloadActivities: () => {},
    reset: () => {},
    uploadFile: () => {},
    addMessage: () => {},
  };

  return (
    <AddCommentActivities
      placeholder="Post an update"
      // @ts-expect-error
      uploadFile={activities.uploadFile}
      usersSearch={API.searchUsers}
      // @ts-expect-error
      sendMessage={activities.addMessage}
    />
  );
};

export default observer(SitesDetailsActivitiesFooter);

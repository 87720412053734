import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #e2e4e6;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  padding: 16px;
  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 18px;
    font-weight: 500;
    line-height: 150%; /* 27px */
    letter-spacing: 0.15px;
    margin: 0;
    padding: 0;
  }
`;

export const Wrapper = styled.div<{ $flex: number }>`
  flex: ${({ $flex }) => $flex};
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow-y: scroll;
`;

export const UnsavedChanges = styled.div`
  border-radius: 6px;
  border: 1px solid #e2e4e9;
  display: flex;
  max-width: 300px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 3px;
  flex-wrap: wrap;

  color: #525866;
  font-size: 12px;
  font-weight: 500;
  margin-left: 16px;
`;

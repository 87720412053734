import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosError } from 'axios';

import Logger from 'vatix-ui/lib/utils/logger';

import DataMixin from 'vatix-ui/lib/utils/stores/DataMixin';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import { isNotFound } from 'utils/api/errors';

import RootStore from '../Root';
import DetailsForm from './DetailsForm';

export default class InspectionDetailsStore extends DataMixin<typeof API, RootStore> {
  @observable error?: AxiosError;

  @observable details?: DetailsForm;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);

    makeObservable(this);

    eventBus.register(this);
  }

  @action.bound
  async loadDetails(formId: string): Promise<void> {
    if (this.isLoaded && this.details?.uuid === formId) {
      return;
    }

    this.isLoaded = false;
    try {
      const { data } = await this.api.loadFormsDetails(formId)();
      runInAction(() => {
        this.error = undefined;
        this.details = new DetailsForm(this.store, this.api, formId, data);
      });
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      // @ts-ignore
      if (!isNotFound(e)) {
        Logger.error(`Invalid load inspection details API response. Form id: ${formId}`, e);
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  async createInspection(templateVersion: string, name: string): Promise<UuidableName | null> {
    try {
      const { data } = await this.api.createInspection({ templateVersion, name })();
      this.store.notification.enqueueSuccessSnackbar('Form created successfully.');
      return data;
    } catch (e) {
      this.store.notification.enqueueErrorSnackbar('Failed to create form.');
      if (!isNotFound(e as AxiosError)) {
        Logger.error('Failed to create form.', e);
      }
      return null;
    }
  }

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;
  }
}

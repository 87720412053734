import styled, { css } from 'styled-components';

export const Actions = styled.div<{ $isActive?: boolean }>`
  border-right: ${({ $isActive }) => ($isActive ? '1px solid #0772e5' : '1px solid #e2e4e6')};
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 0px 0px 8px;
`;

export const Container = styled.div<{ $withoutHeader?: boolean; $isActive?: boolean }>`
  display: flex;
  border-radius: 8px;
  background: #fff;
  border: ${({ $withoutHeader }) => ($withoutHeader ? 'none' : '1px solid #e2e4e6')};
  margin: ${({ $withoutHeader }) => ($withoutHeader ? '' : '16px')};
  margin-top: ${({ $withoutHeader }) => ($withoutHeader ? '' : '8px')};

  ${({ $isActive }) =>
    $isActive &&
    css`
      border: 1px solid #0772e5;
    `}
`;

import React from 'react';

import { ChipProps } from '@mui/material';

import { EntitiesStatus } from 'core/constants';

import Chip from 'components/Chip';
import { VatixChipProps } from 'components/Chip/types';

const StatusChipPropsMapping: Record<string, VatixChipProps> = {
  [EntitiesStatus.Open]: {
    colors: {
      background: '#FEEBCC',
      foreground: '#A55200',
    },
    label: 'Open',
  },
  [EntitiesStatus.Resolved]: {
    colors: {
      background: '#E7F3E6',
      foreground: '#107B03',
    },
    label: 'Resolved',
  },
  [EntitiesStatus.Archived]: {
    colors: {
      background: '#0772E51A',
      foreground: '#0568D1',
    },
    label: 'Archived',
  },
} as const;

const EntitiesStatusChip: React.FunctionComponent<{ status: EntitiesStatus } & ChipProps> = ({
  status,
  ...props
}): React.ReactElement => (
  <Chip id={`task-status-${status}`} size={props.size || 'medium'} {...StatusChipPropsMapping[status]} {...props} />
);

export default EntitiesStatusChip;

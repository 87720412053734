import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosError } from 'axios';

import Logger from 'vatix-ui/lib/utils/logger';

import DataMixin from 'vatix-ui/lib/utils/stores/DataMixin';

import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import { EntityValueType, SiteDetailsType } from 'utils/api/types';
import { isNotFound } from 'utils/api/errors';

import RootStore from '../Root';

export default class SiteDetailsStore extends DataMixin<typeof API, RootStore> {
  @observable error?: AxiosError;

  @observable details?: SiteDetailsType;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);

    makeObservable(this);

    eventBus.register(this);
  }

  @action.bound
  async loadDetails(siteId: string): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadSiteDetails(siteId)();
      runInAction(() => {
        this.error = undefined;
        this.details = data;
      });
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      // @ts-ignore
      if (!isNotFound(e)) {
        Logger.error(`Invalid load site details API response. Task id: ${siteId}`, e);
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  createSite = async (name: string): Promise<boolean> => {
    try {
      await this.api.createSite(name)();
      return true;
    } catch (e) {
      Logger.error('Failed to create site', e);

      return false;
    }
  };

  deleteSite = async (siteId: string): Promise<boolean> => {
    try {
      await this.api.deleteSite(siteId)();
    } catch (e) {
      Logger.error('Failed to delete site', e);
      return false;
    }
    return true;
  };

  editSite = async (data: Record<string, EntityValueType>): Promise<boolean> => {
    if (this.details?.uuid === undefined) {
      return false;
    }
    try {
      const edited = await this.api.editSite(this.details?.uuid, data)();
      this.details = edited.data;
      return true;
    } catch (e) {
      Logger.error('Failed to edit site', e);
      return false;
    }
  };

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;
  }

  @action.bound
  async loadCount(): Promise<number> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadSitesCount()();
      runInAction(() => {
        this.error = undefined;
      });
      return data.count;
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      return 0;
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }
}

import styled from 'styled-components';

export const LastPublished = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 400;
  line-height: 143%; /* 17.16px */
  letter-spacing: 0.17px;
  min-width: fit-content;
  margin-right: 16px;
`;

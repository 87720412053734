import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'vatix-ui/lib/components/Button';

import CustomTextInputField from 'containers/IncidentDetails/components/CustomTextInputField';
import CustomModal from 'components/CustomModal';

import { useStore } from 'utils/hooks/store';

import { DetailsRowContainer, TitleText } from './styles';

const AddSitesModal: React.FunctionComponent<{
  onClose: (created: boolean) => void;
}> = ({ onClose }): React.ReactElement => {
  const { siteDetails, notification } = useStore();

  const [name, setName] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);

  const handleCreate = async (): Promise<void> => {
    setSubmitting(true);
    const created = await siteDetails.createSite(name);

    if (created) {
      notification.enqueueSuccessSnackbar('Site created successfully');
      onClose(true);
    } else {
      notification.enqueueErrorSnackbar('The site could not be created. Something went wrong.');
    }
    setSubmitting(false);
  };

  const onCloseModal = (): void => onClose(false);

  return (
    <CustomModal
      openModal
      onClose={onCloseModal}
      modalTitle="Create New Site"
      cancelButton={
        <Button id="cancel-button" onClick={onCloseModal} variant="outlined" size="large" data-testid="cancelButton">
          Cancel
        </Button>
      }
      submitButton={
        <Button
          id="create-site-button"
          onClick={handleCreate}
          disabled={name === '' || submitting}
          variant="contained"
          size="large"
          data-testid="addButton"
        >
          Create
        </Button>
      }
    >
      <DetailsRowContainer>
        <TitleText>Site Name</TitleText>
        <CustomTextInputField value={name} onChange={(e) => setName((e as unknown) as string)} description="Name" />
      </DetailsRowContainer>
    </CustomModal>
  );
};

export default observer(AddSitesModal);

import styled from 'styled-components';

import { Divider, Grid } from '@mui/material';

export const BackgroundInformationSections = styled.div`
  position: relative;
  overflow-y: scroll;
  margin: 15px 20px 0px;
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledRowWrapper = styled.div`
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 7px;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  background: #ffffff;
  padding: 10px 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const SectionHeader = styled(Grid)`
  display: flex;
  justify-content: center;
  padding: 0px;
  background: rgba(7, 114, 229, 0.04);
  border-radius: 5px;
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: #0772e5;
    margin: 8px;
  }
`;

export const SectionContainerHeader = styled.div<{ $expanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(props) => (props.$expanded ? `1px solid #e0e0e0` : null)};
  padding: 0 20px;
  background: #f5f5f5;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 11px 12px;
`;

export const Section = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 10px;
`;

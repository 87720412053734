import { action, observable, makeObservable, reaction } from 'mobx';
import { AxiosError } from 'axios';

import DataMixin from 'vatix-ui/lib/utils/stores/DataMixin';

import { arrayMove } from '@dnd-kit/sortable';

import { Active, Over } from '@dnd-kit/core';

import _, { set, isEqual } from 'lodash';

import { v4 as uuid } from 'uuid';

import API from 'utils/api';
import RootStore from 'stores/Root';
import { Items } from 'containers/LayoutEditor/components/Layout/types';

import { EntityLayoutType, EntityPropertiesType } from 'utils/api/types';

export default class EntityLayoutStore extends DataMixin<typeof API, RootStore> {
  @observable error?: AxiosError;

  @observable data?: EntityLayoutType;

  // data loaded at first, needed to compare with the current data
  @observable loadedData?: EntityLayoutType;

  @observable lastPublished?: string;

  @observable containers: string[] = [];

  @observable items: Items = {};

  @observable contentOfLayoutWasEdited = false;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);
    makeObservable(this);

    // Reaction to observe changes
    reaction(
      () => [this.data, this.items, this.containers],
      () => {
        this.isModified();
      }
    );
  }

  @action.bound
  async loadLayout(entityType: string): Promise<void> {
    this.isLoaded = false;
    try {
      const {
        data: { layout, createdAt },
      } = await this.api.loadEntityLayout(entityType)();
      this.data = layout;
      this.loadedData = layout;
      this.lastPublished = createdAt;
      this.containers = layout.order;
      this.transformDataForDisplay();
      const fieldsIds = Object.values(this.items).flat();
      this.store.entityFields.changeFieldsToDisabled(fieldsIds as string[]);
      this.error = undefined;
    } catch (err) {
      const e = err as AxiosError;
      this.error = e;
    } finally {
      this.isLoaded = true;
    }
  }

  findItem = (id: string): string | undefined => {
    if (id in this.items) {
      return id;
    }
    return Object.keys(this.items).find((key) => this.items[key].includes(id));
  };

  @action.bound
  transformDataForDisplay(): void {
    if (!this.data) return;
    const result: Items = {};

    this.data.order.forEach((key: string) => {
      result[key] = this.data!.properties[key].order;
    });
    this.items = result;
  }

  getNextContainerId = (): string => {
    const value = uuid();
    return `untitledSection${value}`;
  };

  @action.bound
  addSection(): string {
    if (!this.data) return '';

    const key = this.getNextContainerId();
    this.containers.push(key);

    const properties = _.mapValues(this.items, (value, k) => ({
      type: 'object',
      order: value,
      title: this.getSectionTitle(k),
      description: '',
    }));

    this.data = {
      ...this.data,
      order: this.containers,
      // @ts-ignore
      properties: {
        ...properties,
        [key]: {
          type: 'object',
          order: [],
          title: 'Untitled section',
          description: 'Key Fields',
        },
      },
    };

    this.transformDataForDisplay();
    return key;
  }

  @action.bound
  removeSection(key: string): void {
    this.containers = this.containers.filter((container) => container !== key);
  }

  @action.bound
  reorderSections(active: string, over: string): void {
    const activeIndex = this.containers.indexOf(active);
    const overIndex = this.containers.indexOf(over);

    this.containers = arrayMove(this.containers, activeIndex, overIndex);
  }

  @action.bound
  getSectionTitle(key: string): string {
    if (!this.data) return '';
    return this.data.properties[key].title || 'N/A';
  }

  // items
  @action.bound
  addFieldToContainer(activeContainer: string, newContainer: string, id: string): void {
    this.items = {
      ...this.items,
      [activeContainer]: this.items[activeContainer].filter((field) => field !== id),
      [newContainer]: [...this.items[newContainer], id],
    };
  }

  @action.bound
  modifyItemsInContainer(container: string, items: string[]): void {
    this.items = {
      ...this.items,
      [container]: items,
    };
  }

  @action.bound
  moveItemsBetweenContainers(
    activeContainer: string,
    overContainer: string,
    overId: string,
    active: Active,
    over: Over
  ): void {
    const activeItems = this.items[activeContainer];
    const overItems = this.items[overContainer];
    const activeIndex = activeItems.indexOf(active.id);
    if (!active.rect.current.translated) return;
    const newIndex =
      overId in this.items
        ? overItems.length
        : overItems.indexOf(overId) + (active.rect.current.translated.top > over.rect.top + over.rect.height ? 1 : 0);

    this.items = {
      ...this.items,
      [activeContainer]: activeItems.filter((item) => item !== active.id),
      [overContainer]: [...overItems.slice(0, newIndex), activeItems[activeIndex], ...overItems.slice(newIndex)],
    };
  }

  @action.bound
  setItems(items: Items): void {
    this.items = items;
  }

  @action.bound
  renameSection(sectionId: string, newTitle: string): void {
    if (!this.data) return;
    this.data = set({ ...this.data }, `properties.${sectionId}.title`, newTitle);
  }

  // check if the layout has been modified
  @action.bound
  isModified(): void {
    if (this.loadedData === undefined) return;

    const containersAreDifferent = !isEqual(this.loadedData.order, this.containers);

    const dataAreDifferent = !isEqual(this.data?.properties, this.loadedData?.properties);

    let propertiesAreDifferent = false;
    Object.keys(this.loadedData.properties).forEach((key) => {
      if (key === 'description' || key === 'title') return;
      if (!isEqual(this.loadedData?.properties[key].order, this.items[key])) {
        propertiesAreDifferent = true;
      }
    });

    this.contentOfLayoutWasEdited = containersAreDifferent || propertiesAreDifferent || dataAreDifferent;
  }

  // SAVING
  @action.bound
  async saveLayout(entityType: string): Promise<void> {
    // check if there is no empty section
    const emptySections = this.containers.filter((container) => this.items[container].length === 0);
    if (emptySections.length > 0) {
      this.store.notification.enqueueErrorSnackbar('Sections cannot be empty');
      return;
    }

    // check if all sections names are unique
    const sectionTitles = this.containers.map((container) => this.getSectionTitle(container));
    const uniqueSectionTitles = new Set(sectionTitles);
    if (sectionTitles.length !== uniqueSectionTitles.size) {
      this.store.notification.enqueueErrorSnackbar('Section names must be unique');
      return;
    }
    try {
      const updated = await this.api.updateEntityLayout(entityType, this.transformDataForSave())();
      this.contentOfLayoutWasEdited = false;
      this.lastPublished = updated.data.createdAt;
      this.store.notification.enqueueSuccessSnackbar('Layout published successfully');
    } catch (err) {
      const e = err as AxiosError;
      this.error = e;
      this.store.notification.enqueueErrorSnackbar(e.response?.data.message || 'Could not save layout');
    }
  }

  @action.bound
  transformDataForSave(): EntityLayoutType {
    const result: {
      type: string;
      order: string[];
      properties: EntityPropertiesType;
    } = {
      type: 'object',
      order: [],
      properties: {},
    };

    let newContainers = this.containers;

    _.forEach(this.items, (value, key) => {
      if (!newContainers.includes(key)) return;

      const sectionTitle = this.getSectionTitle(key);
      let newKey = key;

      if (key.startsWith('untitledSection')) {
        newKey = _.camelCase(sectionTitle);
      }

      newContainers = newContainers.map((container) => (container === key ? newKey : container));

      _.set(result.properties, newKey, {
        type: 'object',
        order: value,
        title: sectionTitle,
      });
    });

    result.order = newContainers;

    return result;
  }
}

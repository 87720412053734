import * as Yup from 'yup';

import { FormikErrors, FormikTouched } from 'formik';

import { EntityFieldType, ProtectorType } from 'utils/api/types';

export type EntityFieldTypeWithoutUUID = Omit<EntityFieldType, 'uuid'>;

export const possibleProtectorTypes = Object.values(ProtectorType).filter(
  (i) =>
    i !== ProtectorType.Section &&
    i !== ProtectorType.Status &&
    i !== ProtectorType.MultiChoice &&
    i !== ProtectorType.Image
);

// should allow for numbers and letters
const camelCaseRegex = /^[a-z0-9]+([A-Z0-9][a-z0-9]*)*$/;

export const EntityFiledValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  key: Yup.string()
    .matches(camelCaseRegex, 'Key must be in camel case')
    .required('Required'),
  protectorType: Yup.string().required('Required'),
  description: Yup.string(),
});

export const getBorderStyle = (
  editing: boolean,
  errors: FormikErrors<EntityFieldTypeWithoutUUID>,
  touched: FormikTouched<EntityFieldTypeWithoutUUID>
): string => {
  if (editing) {
    return '1px solid rgba(0, 0, 0, 0.06)';
  }
  if (errors.protectorType && touched.protectorType) {
    return '2px solid rgba(239, 83, 80, 1)';
  }
  return '1px solid rgba(0, 0, 0, 0.23)';
};
